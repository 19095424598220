import { HowItWOrk } from "../../Pages/Routes/Imports";

const constants = {
  USER_ROLE: {
    ADMIN: 1,
    USER: 2,
  },
  DEFUALT_IMAGE_SERVICE: {
    IMAGE:
      "https://designs.ustaffings.com/staging/public/storage/service_type/20240628093248-thumnail_service_type.png",
  },
  DEFUALT_IMAGE_PROFILE: {
    IMAGE: "../../../imgs/avatar/avatar.png",
  },
  IMAGE_PATH: {
    IMAG_PATH: "../../../imgs/",
  },
  FOOTER_SECTION_LINK: {
    ABOUT_US: "/about-us",
    REGISTER: "/register",
    CONTACT: "/contact",
    FACEBOOK: "https://www.facebook.com/ustaffings/",
    INSTARGRAM: "https://www.instagram.com/ustaffings/",
    FAQ: "/faq",
    HowItWOrk: "/how-it-work"
  },
  ACTEPED_DOCUMENT_IMAGE: {
    FORMATS: ["image/jpeg", "image/png", "application/pdf", "image/jpg"],
  },
};
export default constants;
