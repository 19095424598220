import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";

export default function TopWorkers() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const sliderRef = useRef<any>(null);

  const next = () => {
    setCurrentSlide((prevSlide: number) => prevSlide + 1);
  };

  const prev = () => {
    setCurrentSlide((prevSlide: number) => prevSlide - 1);
  };

  const changeAutoPlay = () => {
    setAutoPlay((prevAutoPlay: any) => !prevAutoPlay);
  };

  const updateCurrentSlide = (index: any) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  const buttonStyle = { fontSize: 20, padding: "5px 20px", margin: "5px 0px" };
  const containerStyle = { margin: "5px 0 20px" };
  const settings: any = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    rows: 1,
    lazyLoad: true,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="section-box mt-0">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              Top Support Workers
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Connect with and book support workers in your community
            </p>
          </div>
        </div>
        <div className="container">
          <div className="box-swiper mt-50">
            <div className="swiper-container  swiper-style-2 swiper">
              <Slider {...settings} ref={sliderRef}>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Amelia S</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Isabella</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Willow</h4>

                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Frankie</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Frankie</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Frankie</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Frankie</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Frankie</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>{" "}
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Frankie</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item-5 hover-up wow animate__animated animate__fadeIn">
                  <a href="#">
                    <div className="item-logo">
                      <div className="image-left">
                        <img
                          alt=""
                          src="https://i.pravatar.cc/52"
                          className="rounded"
                        />
                      </div>
                      <div className="text-info-right w-100 mt-2">
                        <h4>Hannah</h4>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-fill text-warning me-1"></i>
                        <i className="ri-star-line text-warning me-1"></i>
                        <i className="ri-star-line text-warning"></i>
                        <span className="font-xs color-text-mutted ml-10">
                          <span>(</span>
                          <span>68</span>
                          <span>)</span>
                        </span>
                      </div>
                      <div className="text-info-bottom mt-5">
                        <span className="font-xs color-text-mutted icon-location">
                          Port Melbourne
                        </span>
                        <span className="font-xs color-text-mutted float-end mt-5">
                          25<span> Support</span>
                        </span>
                        <p className="mt-1">
                          I have joined the USS platform to help you to live
                          your life with dignity, by assisting you..
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Slider>
            </div>

            <div
              className="swiper-button-next swiper-button-next-1"
              onClick={() => sliderRef.current.slickNext()}
            ></div>
            <div
              className="swiper-button-prev swiper-button-prev-1"
              onClick={() => sliderRef.current.slickPrev()}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
}
