import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { useQuery } from "react-query";
import { getLocationData_ } from "../../../Api/WebsiteApi";
import { daysOfWeek } from "../../../Utils/Constants/select_options";
import PrefixForm from "../../../components/RangeSlider/RangeSlider";
import {
  initialValuesStep_2,
  validationSchemaStep_2,
} from "../ProfileMethods/ProfileValidation";
import {
  addModalInitialValuesHandlerStep4,
  ProfileSubmitHandlerStep2,
} from "../ProfileMethods/ProfileSubmitHandlers";
import Autocomplete from "../../CareSeekerProfile/Steps/components/AutoComplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Step2 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const [value, setValues] = useState<any>();

  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep_2);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep4(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep_2);
    }
    setValues(
      Userdata?.user_details?.serviceRadius
        ? Userdata?.user_details?.serviceRadius
        : 0
    );
  }, [Userdata, Userdata?.id, value]);

  const [inputValues, setInputValues] = useState<any>([]);
  const { data: location_ } = useQuery(
    [`data`, inputValues],
    () => getLocationData_(inputValues),
    { keepPreviousData: true, staleTime: 1000 }
  );
console.log(addModalInitialValues,"addModalInitialValues")
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addModalInitialValues}
        validationSchema={validationSchemaStep_2}
        onSubmit={(values, actions) => {
          ProfileSubmitHandlerStep2(values, actions, setStep, UserListRefetch);
        }}
      >
        {({
          errors,
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setFieldTouched,
          touched,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const allSwitchesOn = values.availability_details.every(
              (detail) => detail.toggleSwitch
            );
            if (!allSwitchesOn) {
              setFieldValue("all_day_availability", false);
            }
          }, [
            values.availability_details,
            setFieldValue,
            values.all_day_availability,
          ]);

          const handleStartDateChange = (name: any, value: any) => {
            setFieldValue(name, value);
            if (name === "start_time") {
              values.availability_details.forEach((_, index) => {
                setFieldValue(
                  `availability_details.${index}.start_time`,
                  !values.all_day_availability ? null : value
                );
              });
            } else {
              values.availability_details.forEach((_, index) => {
                setFieldValue(
                  `availability_details.${index}.end_time`,
                  !values.all_day_availability ? null : value
                );
              });
            }
          };

          return (
            <>
              {/* <ScrollToFieldError /> */}
              <Form
                className="seeker-registration-form2 giver-register4"
                onSubmit={handleSubmit}
              >
                {/* <FormGroup>
                <Label>State</Label>
                <SelectComponent
                  loading={sutloading}
                  options={state_aust}
                  value={state_aust?.find(
                    (option: any) => option.value === values.state
                  )}
                  onChange={(selectedOption: any) => {
                    setFieldValue("state", selectedOption?.value);
                    setFieldValue("suburb", "");

                    setInputValue(selectedOption?.value);
                  }}
                  isClearable={true}
                  error={errors?.state ? errors.state : ""}

                  // isMulti={true}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-danger"
                />
              </FormGroup>
              <FormGroup>
                <Label>Suburb</Label>
                <SelectComponent
                  loading={subloading}
                  options={surub_data ? surub_data : []}
                  value={
                    values?.suburb
                      ? surub_data?.find(
                          (option: any) => option.value === values?.suburb
                        )
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    setFieldValue("suburb", parseInt(selectedOption?.value));
                  }}
                  isClearable={true}
                  error={errors?.suburb ? errors.suburb : ""}
                />
                {errors?.suburb && (
                  <div className="text-danger">{errors?.suburb}</div>
                )}
              </FormGroup> */}
                <FormGroup>
                  <Label>Suburb</Label>
                  <Autocomplete
                    setInputValue={setInputValues}
                    loaction_={location_}
                    setFieldValue={setFieldValue}
                    value_data={values?.suburb}
                    values={values}
                  />
                  <ErrorMessage
                    name="suburb"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup className="mt-30 mb-30 service-radius-formgroup">
                  <Label className="mb-0 " for="service_radius">
                    Service Radius
                  </Label>{" "}
                  <PrefixForm
                    rtl={false}
                    setFieldValue={setFieldValue}
                    name="service_radius"
                    value={values?.service_radius}
                  />
                  <div className="mt-0 text-center">
                    {values?.service_radius} km Radius
                  </div>
                  <ErrorMessage
                    name="service_radius"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <Row className="availability-row-form">
                  <div className="col-md-12">
                    <Label className=" mb-20">Availability</Label>
                  </div>
                  <Col lg="4">
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={values.all_day_availability}
                        name="all_day_availability"
                        onChange={() =>
                          setFieldValue(
                            "all_day_availability",
                            !values.all_day_availability
                          )
                        }
                      />
                      &nbsp;
                      <Label className="mb-0" check>
                        All Day Availability
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <Label for="start_time">Start Time</Label>

                      <DatePicker
                        selected={
                          !values.all_day_availability
                            ? null
                            : values?.start_time
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select a time"
                        className="px-4"
                        onChange={(date: any) =>
                          handleStartDateChange("start_time", date)
                        }
                        showIcon
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                          </svg>
                        }
                        disabled={!values.all_day_availability}
                      />
                      <ErrorMessage
                        name="start_time"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <Label for="end_time">End Time</Label>
                      <DatePicker
                        selected={
                          !values.all_day_availability
                            ? null
                            : values?.end_time
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select a time"
                        className="px-4"
                        onChange={(date: any) =>
                          handleStartDateChange("end_time", date)
                        }
                        showIcon
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                          </svg>
                        }
                        disabled={!values.all_day_availability}
                      />
                      <ErrorMessage
                        name="end_time"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <hr />

                <FieldArray name="availability_details">
                  {({ insert, remove, push }) => (
                    <>
                      {values?.availability_details?.length > 0 &&
                        values?.availability_details?.map((detail, index) => (
                          <div key={index} className="mb-3">
                            <Row>
                              <Col>
                                <FormGroup switch>
                                  <Input
                                    type="switch"
                                    checked={detail?.toggleSwitch}
                                    name={`availability_details.${index}.toggleSwitch`}
                                    onChange={() =>
                                      setFieldValue(
                                        `availability_details.${index}.toggleSwitch`,
                                        !detail.toggleSwitch
                                      )
                                    }
                                  />
                                  <Label className="mb-0" check>
                                    {detail.toggleSwitch
                                      ? "Available"
                                      : "Not Available"}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label
                                    for={`availability_details.${index}.day`}
                                  >
                                    Day
                                  </Label>
                                  <Field
                                    as="select"
                                    name={`availability_details.${index}.day`}
                                    className="form-control"
                                    disabled
                                  >
                                    {daysOfWeek
                                      .filter(
                                        (day) =>
                                          !values.availability_details.some(
                                            (detail, i) =>
                                              detail.day === day.value &&
                                              i !== index
                                          )
                                      )
                                      .map((day) => (
                                        <option
                                          key={day.value}
                                          value={day.value}
                                        >
                                          {day.label}
                                        </option>
                                      ))}
                                  </Field>
                                  <ErrorMessage
                                    name={`availability_details.${index}.day`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label
                                    for={`availability_details.${index}.start_time`}
                                  >
                                    Start Time
                                  </Label>

                                  <DatePicker
                                    selected={
                                      !detail.toggleSwitch &&
                                      !values?.all_day_availability
                                        ? null
                                        : detail.toggleSwitch
                                        ? values.availability_details?.[index]
                                            .start_time
                                        : null
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Select a time"
                                    className="px-4"
                                    onChange={(date) =>
                                      setFieldValue(
                                        `availability_details.${index}.start_time`,
                                        date
                                      )
                                    }
                                    // onKeyDown={(e) => {
                                    //   // Prevent typing if the input should be disabled
                                    //   if (isDisabled) {
                                    //     e.preventDefault();
                                    //   }
                                    // }}
                                    showIcon
                                    icon={
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                      >
                                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                                      </svg>
                                    }
                                  />
                                  <ErrorMessage
                                    name={`availability_details.${index}.start_time`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label
                                    for={`availability_details.${index}.end_time`}
                                  >
                                    End Time
                                  </Label>
                                  <DatePicker
                                    selected={
                                      !detail.toggleSwitch &&
                                      !values?.all_day_availability
                                        ? null
                                        : detail.toggleSwitch
                                        ? values.availability_details?.[index]
                                            .end_time
                                        : null
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Select a time"
                                    className="px-4"
                                    onChange={(date) =>
                                      setFieldValue(
                                        `availability_details.${index}.end_time`,
                                        date
                                      )
                                    }
                                    // onKeyDown={(e) => {
                                    //   // Prevent typing if the input should be disabled
                                    //   if (isDisabled) {
                                    //     e.preventDefault();
                                    //   }
                                    // }}
                                    showIcon
                                    icon={
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                      >
                                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                                      </svg>
                                    }
                                  />
                                  <ErrorMessage
                                    name={`availability_details.${index}.end_time`}
                                    component="div"
                                    className="text-danger"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </>
                  )}
                </FieldArray>
                {Object.keys(errors).length === 1 ? (
                  <>
                    {" "}
                    <div>
                      <Alert color="danger" className="mt-30" id="g">
                        Please make sure all fields are filled correctly.
                      </Alert>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                  <Button
                    className="btn btn-brand-2"
                    type="submit"
                    onClick={() => [setStep(3)]}
                  >
                    <i className="ri-arrow-left-line"></i>&nbsp;BACK
                  </Button>
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Step2;
