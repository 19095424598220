import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import InputSelectCommon from "../../../components/CommonSelect/InputSelect";
import { GENDER } from "../../../Utils/Constants/select_options";
import {
  addModalInitialValuesHandlerStep1,
  ProfileSubmitHandlerStep1,
} from "../ProfileMethods/ProfileSubmitHandlers";
import { initialValuesStep1 } from "../ProfileMethods/profileInitialValues";
import { validationSchemaStep1 } from "../ProfileMethods/ProfileValidation";
import {
  getAustState,
  getSubrub,
  getSupportData,
} from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SelectComponent from "../../../components/ReactSelect/ReactSelect";
import DatePickerReact from "../../../components/DatePicker/DatePickerReact";

export const getFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (
    obj: any,
    prefix?: string,
    result: string[] = []
  ) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length === 0) return;

    // Target all types of form fields, not just input
    const element: HTMLElement | null = document.querySelector(
      `input[name='${fieldErrorNames[0]}'], textarea[name='${fieldErrorNames[0]}'], select[name='${fieldErrorNames[0]}']`
    );

    if (!element) return;

    // Scroll and focus on the field with error
    element.scrollIntoView();
    element.focus();
  }, [submitCount, isValid, errors]);

  return null; // This is a side-effect-only component, no UI rendered
};

const Step1 = ({ setStep, Userdata, ListRefetch, UserLoading_data }: any) => {
  const navigate = useNavigate();
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep1);
  const [error_back, setErrorBack] = useState("");
  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep1(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep1);
    }
  }, [Userdata, Userdata?.id]);

  const {
    data: Supprtdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`Supprtdata`], getSupportData, {
    keepPreviousData: true,
  });

  const [inputValue, setInputValue] = useState(
    Userdata?.careseeker_details?.state?.id || ""
  );

  const { data: state_aust } = useQuery([`state_aust`], getAustState, {
    keepPreviousData: true,
    staleTime: 1000,
  });

  const { data: surub_data, isLoading: subloading } = useQuery(
    [`surub_data`, inputValue],
    () => (inputValue ? getSubrub(inputValue) : ""),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );

  return (
    <>
      {UserLoading_data ? (
        <Skeleton count={5} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={addModalInitialValues}
          validationSchema={validationSchemaStep1}
          onSubmit={(values, actions) => {
            ProfileSubmitHandlerStep1(
              values,
              actions,
              setStep,
              navigate,
              ListRefetch,
              Userdata?.careseeker_details?.supportFor?.id,
              setErrorBack
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
          }) => (
            <>
              {/* <ScrollToFieldError /> */}

              <Form
                className="seeker-registration-form2 giver-register4"
                onSubmit={handleSubmit}
              >
                <div className="text-center mb-3 logo-div01">
                  <h3 className="block-subTitle text-center uss-text-info">
                    {/* <span className="uss-text-primary">Create</span> Account */}
                  </h3>
                </div>
                <FormGroup>
                  <Label>Support for </Label>
                  <InputSelectCommon
                    size={1}
                    data={Supprtdata}
                    name="suppot_for_you"
                    selectClass={`form-control col-12 ${`${
                      errors.suppot_for_you ? "is-invalid" : "is-valid"
                    }`}`}
                  />
                  <ErrorMessage
                    name="suppot_for_you"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>First Name</Label>
                  <Field
                    type="text"
                    name="first_name"
                    className={`form-control ${`${
                      errors.first_name ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.first_name || ""}
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Field
                    type="text"
                    name="last_name"
                    className={`form-control ${`${
                      errors.last_name ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.last_name || ""}
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Mobile</Label>
                  <Field
                    type="text"
                    name="mobile"
                    className={`form-control ${`${
                      errors.mobile ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.mobile || ""}
                    disabled={values?.suppot_for_you === "1" ? true : false}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {values?.suppot_for_you === "1" ? (
                  ""
                ) : (
                  <>
                    {error_back && (
                      <>
                        <Alert color="danger" className="mt-30" id="g">
                          You are supporting or creating an account for someone
                          else. Please ensure the email is not the same as your
                          account email, and make sure to change it
                        </Alert>
                      </>
                    )}
                  </>
                )}
                <FormGroup>
                  <Label>Email</Label>
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${`${
                      errors.email ? "is-invalid" : "is-valid"
                    }`}`}
                    value={values?.email || ""}
                    disabled={values?.suppot_for_you === "1" ? true : false}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Date of Birth</Label>
                  <DatePickerReact
                    selected={values.dob ? new Date(values.dob) : null}
                    onChange={(date: any) => setFieldValue("dob", date)}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    placeholderText="DD/MM/YYYY"
                    className={`form-control ${
                      errors.dob ? "is-invalid" : "is-valid"
                    }`}
                    showIcon
                    maxDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 18)
                      )
                    }
                  />
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Gender</Label>
                  <Row>
                    {GENDER?.map((item: any, index: number) => (
                      <div className="col-2" key={index}>
                        <Field
                          type="radio"
                          name="gender"
                          value={item.id || ""}
                          checked={values?.gender === item?.id?.toString()}
                        />
                        &nbsp;
                        <Label className="form-check-label">{item.name}</Label>
                      </div>
                    ))}
                  </Row>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <Card>
                  <CardBody>
                    <FormGroup>
                      <Label>Address</Label>
                      <hr />
                      <FormGroup>
                        <Label>Street Number</Label>
                        <input
                          type="text"
                          name="street_number"
                          className={`form-control ${
                            errors.street_number ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.street_number || ""}
                          onChange={handleChange}
                        />
                        {errors.street_number && (
                          <div className="text-danger">
                            {errors.street_number}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Address Line - 1</Label>
                        <input
                          type="text"
                          name="address_line_one"
                          className={`form-control ${
                            errors.address_line_one ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.address_line_one || ""}
                          onChange={handleChange}
                        />
                        {errors.address_line_one && (
                          <div className="text-danger">
                            {errors.address_line_one}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Address Line - 2</Label>
                        <input
                          type="text"
                          name="address_line_two"
                          className={`form-control ${
                            errors.address_line_two ? "is-invalid" : "is-valid"
                          }`}
                          value={values?.address_line_two || ""}
                          onChange={handleChange}
                        />
                        {errors.address_line_two && (
                          <div className="text-danger">
                            {errors.address_line_two}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label>State</Label>
                        <SelectComponent
                          loading={subloading}
                          options={state_aust}
                          value={state_aust?.find(
                            (option: any) => option.value === values.state
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue("state", selectedOption?.value);
                            setFieldValue("suburb", null);
                            setInputValue(selectedOption?.value);
                          }}
                          error={errors?.state ? errors?.state : ""}
                          isClearable
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Suburb</Label>
                        <SelectComponent
                          loading={subloading}
                          options={surub_data ? surub_data : []}
                          value={
                            values?.suburb && surub_data
                              ? surub_data?.find(
                                  (option: any) =>
                                    option.value === values?.suburb
                                )
                              : ""
                          }
                          onChange={(selectedOption) => {
                            setFieldValue("suburb", selectedOption?.value);
                          }}
                          error={errors?.suburb}
                          isClearable
                        />
                        <ErrorMessage
                          name="suburb"
                          component="div"
                          className="text-danger"
                        />
                      </FormGroup>
                    </FormGroup>
                  </CardBody>
                </Card>{" "}
                {Object.keys(errors)?.length === 1 ? (
                  <>
                    {" "}
                    <div>
                      <Alert color="danger" className="mt-30" id="g">
                        Please make sure all fields are filled correctly.
                      </Alert>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="text-center mt-5 d-float-center justify-content-center gap-15 mt-4">
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default Step1;
