import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import { useQuery } from "react-query";
import { getserviceTypeList } from "../../../../Api/WebsiteApi";
import { Link } from "react-router-dom";

export default function OurSupport() {
  const navigate = useNavigate();
  const sliderRef = useRef<any>(null); // Slider reference
  const {
    data: dataList,
    error: dataError, 
    isLoading: dataLoading,
    refetch: dataListRefetch,
  }: any = useQuery([`services`], () => getserviceTypeList(), {
    keepPreviousData: true,
    staleTime: 1000,
  });
  const slidesData = [
    {
      href: "/services",
      title: "Exercise Physiology",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/physiotherapy.png`,
    },
    {
      href: "/services",
      title: "Art Therapy",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/art-therapy.png`,
    },
    {
      href: "/services",
      title: "Child Life Therapy",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/child-life-therapy.png`,
    },
    {
      href: "/services",
      title: "Genetic Counselling",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/genetic-counselling.png`,
    },
    {
      href: "/services",
      title: "Exercise Physiology",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/physiotherapy.png`,
    },
    {
      href: "/services",
      title: "Art Therapy",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/art-therapy.png`,
    },
    {
      href: "/services",
      title: "Child Life Therapy",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/child-life-therapy.png`,
    },
    {
      href: "/services",
      title: "Genetic Counselling",
      backgroundImage: `${constants.IMAGE_PATH.IMAG_PATH}services/genetic-counselling.png`,
    },
  ];

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   arrows: false, // Disable internal arrows to use external controls
  // };
  const settings: any = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    // nextArrow: true,
    // prevArrow: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="section-box mt-20">
      <div className="section-box wow animate__animated animate__fadeIn">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              Our Support
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Search and connect with the right support worker faster
            </p>
          </div>
          <div className="box-swiper mt-50 our-support-swiper">
            <Slider {...settings} ref={sliderRef}>
              {dataList?.data?.map((slide: any, index: any) => (
                <div className="swiper-slide01 hover-up" key={index}>
                  <div
                    className="card-grid-5 card-category hover-up"
                    style={{
                      backgroundImage: `url(${slide.thumbnailImg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "250px",
                      width: "100%",
                      borderRadius: "10px",
                      marginRight: "30px",
                    }}
                  >
                    <Link to={`/services-detailes?id=${slide?.id}`}  >
                      <div className="box-cover-img">
                        <div className="content-bottom">
                          <h6 className="color-white mb-5">{slide?.name}</h6>
                          <p className="color-white font-xs">
                            <span></span>
                            <span> Workers Available</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
            <div
              className="swiper-button-next swiper-button-next-1"
              onClick={() => sliderRef.current.slickNext()}
            ></div>
            <div
              className="swiper-button-prev swiper-button-prev-1"
              onClick={() => sliderRef.current.slickPrev()}
            ></div>
          </div>
          <div className="mt-50 text-center">
            <a
              className="btn btn-default"
              href="#"
              onClick={() => navigate("/services")}
            >
              View More
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
