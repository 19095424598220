import { useQuery } from "react-query";
import Subscribe from "../Home/Containers/Subscribe";
import ServiceDetiles from "./Containers/ServiceDetiles";
import { getserviceSingle, getserviceTypeList } from "../../../Api/WebsiteApi";
import { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { useSearchParams } from "react-router-dom";

export default function ServiceDetilesIndex(_: any) {
  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("id");
  const {
    data: data,
    error: dataError,
    isLoading: dataLoading,
    refetch: dataListRefetch,
  }: any = useQuery(
    [`services_`, serviceId],
    () => getserviceSingle(serviceId),
    {
      keepPreviousData: true,
    }
  );
  const {
    data: servicelist,
    error: servicelistError,
    isLoading: servicelistLoading,
    refetch: servicelistListRefetch,
  }: any = useQuery([`servicelist`, serviceId], () => getserviceTypeList(), {
    keepPreviousData: true,
  });

  return (
    <>
      {dataLoading ? (
        <Loader />
      ) : (
        <>
          <ServiceDetiles
            data={data}
            servicelist={servicelist?.data?.filter((item: any) => {
              return item.id?.toString() !== serviceId;
            })}
          />
          <Subscribe />
        </>
      )}
    </>
  );
}
