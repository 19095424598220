import { useQuery } from "react-query";
import { getCareGiverSingle } from "../../../Api/WebsiteApi";
import Loader from "../../../components/Loader/Loader";
import {
  calculateExperience,
  calculateExperience_exp,
  calculateYearsOfExperience,
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
} from "../../../Utils/helpers/helpers";
import { useState } from "react";
import { FullCalendarIndex } from "../../../components/FullCander/FullViewCalander";
import ModalContainer from "../../../components/Modal/ModalContainer";
import moment from "moment";
import TopSection from "./SIngleCareGiver/TopSection";
import Overview from "./SIngleCareGiver/Overview";
import ShortBio from "./SIngleCareGiver/ShortBio";
import Availability from "./SIngleCareGiver/Avalibilty";
import Reviews from "./SIngleCareGiver/Reviews";
import TabIndex from "./SIngleCareGiver/Tabindex";
import { Col, Row } from "reactstrap";
import ModalContainers from "../../../components/Modal/ModalContainers";
import SlotModal from "./SIngleCareGiver/Booking/SlotModal";

export default function SIngleCareGiver() {
  const [tab, setTab] = useState(1);
  const currentUrl = window.location.href;

  // Step 2: Create a URL object
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  const id = params.get("id");
  const { data: caregiver, isLoading: careGiversLoading }: any = useQuery(
    [`caregiver`],
    () => getCareGiverSingle(id),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );

  const [pen_modal, setopenmoadl] = useState(false);
  const [service_provides, setservice_provides] = useState();
  const data_path = caregiver?.user_details;
  const calculateExperienceExp__ = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);

    const years = end.diff(start, "years");
    start.add(years, "years");

    const months = end.diff(start, "months");
    start.add(months, "months");

    const weeks = end.diff(start, "weeks");
    start.add(weeks, "weeks");

    const days = end.diff(start, "days");

    return { years, months, weeks, days };
  };

  // Function to get the total experience and display the most significant unit
  const getTotalExperience = (
    experiences: { startDate: string; endDate: string }[]
  ) => {
    const total = experiences?.reduce(
      (acc, experience) => {
        const { years, months, weeks, days } = calculateExperienceExp__(
          experience.startDate,
          experience.endDate
        );

        acc.years += years;
        acc.months += months;
        acc.weeks += weeks;
        acc.days += days;

        return acc;
      },
      { years: 0, months: 0, weeks: 0, days: 0 } // Initial values
    );

    // Normalize the total experience
    if (total?.days >= 7) {
      total.weeks += Math.floor(total?.days / 7);
      total.days %= 7;
    }
    if (total?.weeks >= 4) {
      total.months += Math.floor(total?.weeks / 4);
      total.weeks %= 4;
    }
    if (total?.months >= 12) {
      total.years += Math.floor(total?.months / 12);
      total.months %= 12;
    }

    // Determine the most significant unit to display
    if (total?.years > 0) {
      return `${total.years} year${total?.years > 1 ? "s" : ""}`;
    } else if (total?.months > 0) {
      return `${total.months} month${total?.months > 1 ? "s" : ""}`;
    } else if (total?.weeks > 0) {
      return `${total?.weeks} week${total?.weeks > 1 ? "s" : ""}`;
    } else {
      return `${total?.days} day${total?.days > 1 ? "s" : ""}`;
    }
  };
  // console.log(totalExperience, "totalExperience");

  const view_data = (value: any, map_data: any, namez: any, icon: any) => {
    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [showAll, setShowAll] = useState(false); // State to control showing all items

    // const displayedItems = showAll ? map_data : map_data?.slice(0, 2); // Show all items or only the first three

    return (
      <div className="col-md-6 sub-box-content">
        <div className="find-giver-view-sub-box mb-3">
          <h6 className="mt-0 mb-1 giver-sub-head">
            {icon}&nbsp;{value}
          </h6>
          <p className="d-float-center">
            {map_data?.map((item: any, index: any) => (
              <span key={index} className="m-0 mt-1 care-giver-sub-list">
                <strong className="ms-1 mt-2">
                  {item?.[namez]?.name}
                  {index < map_data?.length - 1 ? "," : ""}
                </strong>
              </span>
            ))}
            {/* Conditionally render "+More" link if there are more than three items */}
          </p>
        </div>
      </div>
    );
  };
  const view_dataExp = (map_data: any) => {
    return (
      <ul>
        {map_data?.map((item: any, index: any) => {
          return (
            <li key={index}>
              <Row>
                <Col lg="10">
                  {capitalizeFirstLetterOfEachWord(item?.companyName)} (
                  {calculateExperience_exp(item?.startDate, item?.endDate)}) in{" "}
                  {capitalizeFirstLetterOfEachWord(item?.designation)}
                </Col>
                <Col lg="2">
                  <span>
                    <em>
                      {moment(item?.startDate).format("YYYY")} -{" "}
                      {moment(item?.endDate).format("YYYY")}
                    </em>
                  </span>
                </Col>
              </Row>
              <hr />
              <p>{capitalizeFirstLetterOfEachWord(item?.description)}</p>
            </li>
          );
        })}
      </ul>
    );
  };

  const view_dataEdu = (map_data: any) => {
    return (
      <>
        <ul>
          {map_data?.map((item: any, index: any) => {
            return (
              <>
                <li key={index}>
                  <Row>
                    <Col lg="10">
                      {capitalizeFirstLetterOfEachWord(item?.courseName)} from{" "}
                      {capitalizeFirstLetterOfEachWord(item?.institutionName)}
                    </Col>
                    <Col lg="2">
                      <span className="">
                        <em>
                          {moment(item?.startDate).format("YYYY")}-
                          {moment(item?.endDate).format("YYYY")}
                        </em>
                      </span>
                    </Col>
                  </Row>
                  <hr />
                  <p>{capitalizeFirstLetterOfEachWord(item?.description)}</p>
                </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
  };
  return (
    <>
      <style>
        {`
          .text-description { max-width: max-content; }
          .sidebar-list-job ul li .sidebar-text-info span, li, p { font-family: 'Satoshi-Medium'; }
          .font-md { font-family: 'Satoshi-Bold'; }
          .view-care-givers .giver-view-info .badge { 
            font-size: 13px;
            font-weight: 800;
          }
            .fc .fc-view-harness {
            height: calc(100vh - -350px)!important;
            }
            .sticky-bar.stick {
            z-index: 0;
            }
        `}
      </style>
      {careGiversLoading && <Loader />}
      <div className="view-care-givers">
        <TopSection
          totalExperience={getTotalExperience}
          setTab={setTab}
          caregiver={caregiver}
          data_path={data_path}
          centeredToggle={centeredToggle}
        />

        <section className="section-box mt-25">
          <div className="container">
            <div className="row find-giver-row">
              <Overview
                totalExperience={getTotalExperience}
                setTab={setTab}
                caregiver={caregiver}
                data_path={data_path}
              />

              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="box-nav-tabs mt-0 mb-5">
                  <TabIndex setTab={setTab} tab={tab} />
                </div>
                <div className="content-single">
                  <div className="tab-content">
                    {tab === 1 && (
                      <>
                        <ShortBio
                          view_dataExp={view_dataExp}
                          data_path={data_path}
                          view_data={view_data}
                          view_dataEdu={view_dataEdu}
                          setopenmoadl={setopenmoadl}
                          centeredToggle={centeredToggle}
                        />
                      </>
                    )}

                    {tab === 2 && (
                      <>
                        <Availability data_path={data_path} />
                      </>
                    )}
                    {tab === 3 && (
                      <>
                        <Reviews />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Modal Popup Full Screen  */}
      {centred && (
        <>
          {" "}
          <ModalContainers
            toggle={centeredToggle}
            isOpen={centred}
            onClosed={onCloseModal}
            children={
              <SlotModal
                isClose={centeredToggle}
                caregiver={id}
                service_providers={caregiver?.user_details?.service_provides}
              />
            }
            size={"xl"}
          />
        </>
      )}
      <ModalContainer title="" isOpen={pen_modal} setIsopen={setopenmoadl}>
        Modal
      </ModalContainer>
    </>
  );
}
