import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import { AuthChecker, setValidationErrors } from "../../../../../Utils/helpers/helpers";

export const ContactSubmitHandler = (
  values: any,
  actions: any,
  navigate: any
) => {
  api
    .post("/auth/login", values, false)
    .then(async function ([success, response]: any) {
      if (response?.accessToken) {
        const TOKEN = response?.accessToken;
        const ROLE = response?.admin;
        const USER_ID = response?.user_id;
        localStorage?.setItem("AUTH_TOKEN", TOKEN);
        localStorage?.setItem(
          "ROLE",
          response?.admin === true
            ? "admin"
            : response?.member_membership_type === 2
            ? "associative"
            : "member"
        );
        localStorage?.setItem("USER_ID", USER_ID);
        if (values?.remember_me) {
          localStorage?.setItem("USERNAME", values?.email);
          localStorage?.setItem("PASSWORD", values?.password);
        }
        AuthChecker(navigate, "/");
      }
      if (response?.errors) {
        actions.setSubmitting(false);
        setValidationErrors(response?.errors, actions);
      }
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          toastId: "Invalid-login-details",
        });
      } else {
        toast?.error("Unexpected error occurred", {
          toastId: "error-occurred",
        });
      }
    });
};
