import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getFAQList } from "../../../../Api/WebsiteApi";

const FAQPage = () => {
  const {
    data: ListItems,
    error: ListItemsError,
    isLoading: ListItemsLoading,
    refetch: ListItemsListRefetch,
  } = useQuery(["getFAQ"], () => getFAQList(), {
    keepPreviousData: true,
  });

  const groupedByTitle = ListItems?.reduce(
    (acc: { [x: string]: any[] }, item: { title: string | number }) => {
      if (!acc[item.title]) {
        acc[item.title] = [];
      }
      acc[item.title].push(item);
      return acc;
    },
    {}
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listFormat = groupedByTitle
    ? Object.keys(groupedByTitle).map((title) => ({
        title,
        questions:
          groupedByTitle[title].map((question: any) => ({
            ...question,
            isOpen: false, // Initialize each question as not open
          })) || [],
      }))
    : [];
  useEffect(() => {
    setFaqData(listFormat);
  }, [ListItemsLoading]);
  const [faqData, setFaqData] = useState(listFormat || []);

  const toggleOpen = (itemIndex: number, questionIndex: string | number) => {
    const newData = [...faqData];
    newData[itemIndex].questions[questionIndex].isOpen =
      !newData[itemIndex].questions[questionIndex].isOpen;
    setFaqData(newData);
  };

  return (
    <>
      <section className="section-box">
        <div className="breadcrumb-cover bg-img-about faq-breadcrumb">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="mb-10">FAQ</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-30 faq-company01">
        <div className="post-loop-grid">
          <div className="container">
            <div className="faq-div01">
              <div className="row">
                <div className="col-md-12 faq-div01">
                  <div
                    className="panel-group"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    {faqData?.map((item, itemIndex) => (
                      <div key={item?.title}>
                        <h3>{item?.title}</h3>
                        {item?.questions?.map(
                          (question: any, questionIndex: any) => (
                            <FAQItem
                              key={question?.id}
                              question={question?.question}
                              answer={question?.answer}
                              isOpen={question?.isOpen}
                              onClick={() =>
                                toggleOpen(itemIndex, questionIndex)
                              }
                            />
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FAQItem = ({ question, answer, isOpen, onClick, link_path }: any) => (
  <div className="panel panel-default">
    <div className="panel-heading" role="tab">
      <h4 className="panel-title">
        <a className={"collapsed"} onClick={onClick}>
          {question}
        </a>
      </h4>
    </div>
    {isOpen && (
      <div className="panel-body">
        <div dangerouslySetInnerHTML={{ __html: answer }}></div>
      </div>
    )}
  </div>
);

export default FAQPage;
