/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step4";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step2";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getHolidays, getUserData } from "../../Api/WebsiteApi";
import { Button } from "reactstrap";

const CreateProfile = () => {
  const navigate = useNavigate();

  const {
    data: Userdata,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`user_`], () => getUserData(), {
    keepPreviousData: true,
  });
  const {
    data: holiday,
    error: holidayError,
    isLoading: holidayLoading,
    refetch: holidayListRefetch,
  }: any = useQuery([`public_holidays`], getHolidays, {
    keepPreviousData: true,
  });
  // console.log(holiday, "holiday");
  const [step, setStep] = useState(
    Userdata?.user_details !== null ? Userdata?.user_details?.nextStep : 1
  );

  const nextStep =
    Userdata?.user_details !== null ? Userdata?.user_details?.nextStep : 1;
  // console.log(localStorage.getItem("AUTH_TOKEN"));
  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN")) {
      const currentStep = step || nextStep;
      // navigate(`/create-profile-caregiver/${currentStep}`);
      setStep(currentStep);
    } else {
      navigate("/caregiver-create-account");
    }
  }, [Userdata?.user_details, nextStep, navigate, step]);
  // console.log(step, "currentStep");
  const isStepDisabled: any = (stepNumber: number) => stepNumber > nextStep;

  return (
    <>
      <div className="authincation h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-10 login-page01">
              <div className="authincation-content card card-body py-4">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="text-center mb-3 logo-div01">
                      <h3 className="block-subTitle text-center uss-text-info">
                        <span className="uss-text-primary">Create</span> Care
                        Giver Profile
                      </h3>
                    </div>
                    <div className="login-page01 wizard-form01">
                      <div className="stepwizard">
                        <div className="stepwizard-row setup-panel">
                          {[1, 2, 3, 4, 5, 6].map((stepNumber) => (
                            <div
                              className={`stepwizard-step ${
                                stepNumber < nextStep ? "success" : ""
                              }`}
                              key={stepNumber}
                            >
                              <button
                                className={`btn ${
                                  // stepNumber === step
                                  //   ? "btn-default"
                                  //   :
                                  stepNumber <= nextStep
                                    ? "btn-primary"
                                    : "btn-default"
                                } btn-circle`}
                                disabled={isStepDisabled(stepNumber)}
                                onClick={() => {
                                  setStep(stepNumber);
                                }}
                              >
                                {stepNumber < nextStep ? (
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  stepNumber || step
                                )}
                                {/* {stepNumber} */}
                                {/* <i className="fa fa-check" aria-hidden="true"></i> */}
                              </button>
                              <p>
                                <span
                                  id={`steps-${stepNumber}_tick`}
                                  className="d-none"
                                >
                                  <i className="fa fa-check"></i>
                                </span>{" "}
                                Step {stepNumber}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {step === 1 && (
                      <>
                        <Step1
                          setStep={setStep}
                          Userdata={Userdata}
                          UserListRefetch={UserListRefetch}
                          holiday={holiday}
                        />
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <Step4
                          setStep={setStep}
                          Userdata={Userdata}
                          UserListRefetch={UserListRefetch}
                          holiday={holiday}
                        />
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <Step3
                          setStep={setStep}
                          step={step}
                          UserListRefetch={UserListRefetch}
                          holiday={holiday}
                        />
                      </>
                    )}
                    {step === 4 && (
                      <>
                        <Step2
                          setStep={setStep}
                          Userdata={Userdata}
                          UserListRefetch={UserListRefetch}
                          holiday={holiday}
                        />
                      </>
                    )}
                    {step === 5 && (
                      <>
                        <Step5
                          setStep={setStep}
                          Userdata={Userdata}
                          UserListRefetch={UserListRefetch}
                          holiday={holiday}
                        />
                      </>
                    )}
                    {step === 6 && (
                      <>
                        <Step6
                          setStep={setStep}
                          Userdata={Userdata}
                          UserListRefetch_data={UserListRefetch}
                          holiday={holiday}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateProfile;
