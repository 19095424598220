import Swal from "sweetalert2";
import { api } from "../../../../../Api/Api";
import moment from "moment";
import {
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
} from "../../../../../Utils/helpers/helpers";
import axios from "axios";
import { BaseUrl } from "../../../../../Api/BaseUrl";

export const AmountClickHandler = (
  values: any,
  setAmount: any,
  caregiver: any,
  date_select: any,
  setCentered: any
) => {
  const data = {
    caregiver_id: caregiver, //pass either id or encrypted id
    start_date: moment(date_select).format("YYYY-MM-DD"),
    booking_time: {
      start_time: moment(values?.timeFrom).format("HH:mm:ss"),
      end_time: moment(values?.timeTo).format("HH:mm:ss"),
    },
  };

  const headers = {
    mode: "cors",
    referrerPolicy: "origin",
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    "X-Requested-With": "XMLHttpRequest", // Example of a Bearer token
  };

  axios
    .post(`${BaseUrl}/user/booking/bill_amount`, data, { headers })
    .then((response) => {
      console.log("User created:", response?.data);
      setCentered(true);
      setAmount(response?.data?.amount?.totalCharges);
    })
    .catch((error) => {
      console.error("Error creating user:", error);
      Swal.fire({
        title: "Sorry!",
        text: error?.response?.data?.message,
        icon: "info",
      });
    });
};
export const EmailClickHandler = (
  values: any,
  setDataFull: any,
  setCentered: any,
  setCentered_: any,
  setValue: any
) => {
  const data = { email: values?.emailOrPhone };

  const headers = {
    mode: "cors",
    referrerPolicy: "origin",
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    "X-Requested-With": "XMLHttpRequest", // Example of a Bearer token
  };

  axios
    .post(`${BaseUrl}/user/booking/web/start`, data, { headers })
    .then((response) => {
      console.log("User created:", response?.data);
      setCentered(true);
      setDataFull(response?.data);
    })
    .catch((error) => {
      console.error("Error creating user:", error);
      if (error?.response?.data?.message.includes("register")) {
        setCentered_(true);
        setValue(1);
      } else {
        Swal.fire({
          title: "Sorry!",
          text: error?.response?.data?.message,
          icon: "info",
        });
      }
    });
};
export const OTpEmailClickHandler = (
  values: any,
  setDataFull: any,

  setCentered: any,
  setCentered_: any,
  setValue: any,
  data_full_: any
) => {
  console.log(data_full_,"ponnu");
  const data = {
    otp: values.otp.join(""),
    verification_code: data_full_?.data?.verificationCode,
  };

  const headers = {
    mode: "cors",
    referrerPolicy: "origin",
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    "X-Requested-With": "XMLHttpRequest", // Example of a Bearer token
  };

  axios
    .post(`${BaseUrl}/user/booking/web/email/verify`, data, { headers })
    .then((response) => {
      console.log("User created:", response?.data);
      setCentered(true);
      setDataFull(response?.data);
    })
    .catch((error) => {
      console.error("Error creating user:", error);
      if (error?.response?.data?.message.includes("register")) {
        setCentered_(true);
        setValue(1);
      } else {
        Swal.fire({
          title: "Sorry!",
          text: error?.response?.data?.message,
          icon: "info",
        });
      }
    });
};
export const BookingClickHandler = (
  values: any,
  DataFull: any,
  setCentered: any,
  service_providers: any,
  setValue: any,
  values_: any,
  setALlData: any
) => {
  const new_array: any = [];
  service_providers?.map((item: any) => {
    new_array?.push(item?.serviceProvides?.id);
  });
  const data = {
    verification_code: DataFull?.data?.verificationCode?.toString(),
    // otp: values.otp.join(""),
    booking_for_id: values?.selectedService, // either id or encrypted id
    caregiver_id: service_providers?.[0]?.userId, //either id or encrypted id
    service_type_id: new_array,
    start_date: moment(values_?.start_date).format("YYYY-MM-DD"),

    booking_time: {
      start_time: moment(values_?.start_time).format("HH:mm:ss"),
      end_time: moment(values_?.end_time).format("HH:mm:ss"),
    },
  };

  const headers = {
    mode: "cors",
    referrerPolicy: "origin",
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    "X-Requested-With": "XMLHttpRequest", // Example of a Bearer token
  };

  axios
    .post(`${BaseUrl}/user/booking/web/initiate`, data, { headers })
    .then((response) => {
      console.log("User created:", response?.data);
      setALlData(response?.data);
      setCentered(true);
      setValue(2);
    })
    .catch((error) => {
      console.error("Error creating user:", error);
      if (error?.response?.data?.message?.includes("register")) {
        setCentered(true);
        setValue(2);
      } else {
        Swal.fire({
          title: "Sorry!",
          text: error?.response?.data?.message,
          icon: "info",
        });
      }
    });
};
