import React from "react";

const FAQPage = () => {
  return (
    <>
      <section className="section-box">
        <div className="breacrumb-cover bg-img-about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="mb-10">Terms & Conditions</h2>
              </div>
              {/* <div className="col-lg-6 text-lg-end">
                <ul className="breadcrumbs mt-40">
                  <li>
                    <a className="home-icon" href="#">
                      Home
                    </a>
                  </li>
                  <li>About Us</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-50 faq-company01">
        <div className="post-loop-grid">
          <div className="container">
            <div className="terms-conditions">
              <h6 className="f-18 color-text-mutted text-uppercase">
                Our 
              </h6>
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                Terms & Conditions
              </h2>
              <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              A Terms and Conditions agreement acts as a legal contract between you (the company) and the user. 
              It's where you maintain your rights to exclude users from your app in the event that they abuse 
              your website/app, set out the rules for using your service and note other important details and disclaimers.
              </p>
              <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              Having a Terms and Conditions agreement is completely optional. No laws require you to have one. 
              Not even the super-strict and wide-reaching General Data Protection Regulation (GDPR).
              </p>
              <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              Your Terms and Conditions agreement will be uniquely yours. While some clauses are standard and commonly seen in 
              pretty much every Terms and Conditions agreement, it's up to you to set the rules and guidelines that the user must agree to.
              </p>              
              <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              Terms and Conditions agreements are also known as Terms of Service or Terms of Use agreements. 
              These terms are interchangeable, practically speaking. More rarely, it may be called something like an End User Services Agreement.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FAQItem = ({ id, headingId, question, answer }: any) => (
  <div className="panel panel-default">
    <div className="panel-heading" role="tab" id={headingId}>
      <h4 className="panel-title">
        <a
          className="collapsed"
          role="button"
          data-toggle="collapse"
          data-parent={`#${headingId}`}
          href={`#${id}`}
          aria-expanded="true"
          aria-controls={id}
        >
          {question}
        </a>
      </h4>
    </div>
    <div
      id={id}
      className="panel-collapse collapse"
      role="tabpanel"
      aria-labelledby={headingId}
    >
      <div className="panel-body">{answer}</div>
    </div>
  </div>
);

export default FAQPage;
