import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const LayoutContainer: React.FC = () => {
  console.log(window.location.href, "asdjshdjashdja");
  const pathnames: any = window.location.href;
  const url = pathnames;
  const urlObj = new URL(url);

  // Extract the pathname (e.g., "/NDIS-approval")
  const pathname = urlObj.pathname;
  const splitPart = pathname.split("/")[1]; // "NDIS-approval"

  // Extract the query parameter "id"
  const id = urlObj.searchParams.get("id"); // "13123"

  console.log(splitPart); // Output: "NDIS-approval"
  console.log(id);
  return (
    <>
      {splitPart === "NDIS-approval" ||
      splitPart === "NDIS-approval-success" ? (
        <>
          <Outlet />
        </>
      ) : (
        <>
          <Header />
          <div>
            <Outlet />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default LayoutContainer;
