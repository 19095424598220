import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import ChatRoom from "./Chat";
import {
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
} from "../../../../Utils/helpers/helpers";

export default function Overview({
  data_path,
  totalExperience,
  caregiver,
  setTab,
}: any) {
  const [openmodal, setOpenModal] = useState(false);
  const [values, setValues] = useState({
    maxRate_: 0,
    minRate_: 0,
  });
  useEffect(() => {
    const rates: any = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data_path?.caregiver_charges?.length !== 0
      ? data_path?.caregiver_charges?.map((charge: any) =>
          rates?.push(Number(charge.rate ? charge.rate : 0))
        )
      : [];

    const minRate = rates?.length !== 0 ? Math.min(...rates) : 0;
    const maxRate = rates?.length !== 0 ? Math.max(...rates) : 0;
    setValues({
      ...values,
      maxRate_: maxRate,
      minRate_: minRate,
    });

    // return {
    //   minRate: isFinite(minRate) ? minRate : 0,
    //   maxRate: isFinite(maxRate) ? maxRate : 0,
    // };
  }, [data_path?.caregiver_charges]);

  return (
    <>
      {" "}
      <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-lg-30 find-giver-left-aside">
        <div className="sidebar-border">
          <h5 className="f-18">Overview</h5>
          <div className="sidebar-list-job">
            <ul>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-account-circle-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Full Name</span>
                  <strong className="small-heading">
                    {capitalizeFirstLetterOfEachWord(caregiver?.firstName)}{"  "}
                    {capitalizeFirstLetterOfEachWord(caregiver?.lastName)}
                  </strong>
                </div>
              </li>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-service-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Profile Type</span>
                  <strong className="small-heading">
                    {data_path?.profileType?.name}
                  </strong>
                </div>
              </li>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-briefcase-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Experience</span>
                  <strong className="small-heading">
                    {totalExperience(data_path?.work_experiences)}
                  </strong>
                </div>
              </li>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-money-dollar-circle-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Hourly Charged</span>
                  <strong className="small-heading">
                    {(values?.maxRate_ || values?.minRate_) && (
                      <>
                        {" "}
                        {values?.minRate_ ? "$" + values?.minRate_ + " - " : ""}
                        {values?.maxRate_ ? "$" + values?.maxRate_ : ""}
                      </>
                    )}
                  </strong>
                </div>
              </li>
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-map-pin-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Location</span>
                  <strong className="small-heading">
                    {CapitalFirstLetter(data_path?.addressSuburb?.locality)}
                    &nbsp;-&nbsp;(
                    {data_path?.addressSuburb?.state}{" "}
                    {data_path?.addressSuburb?.postcode})
                  </strong>
                </div>
              </li>
              {/* <li>
                <div className="sidebar-icon-item">
                  <i className="ri-map-pin-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Address Suburb</span>
                  <strong className="small-heading">
                    {data_path?.addressSuburb?.locality}(
                    {data_path?.addressSuburb?.state}{" "}
                    {data_path?.addressSuburb?.postcode})
                  </strong>
                </div>
              </li> */}
              {/* <li>
              <div className="sidebar-icon-item">
                <i className="ri-money-dollar-circle-line"></i>
              </div>
              <div className="sidebar-text-info">
                <span className="text-description">
                  Expected Salary
                </span>
                <strong className="small-heading">$26k - $30k</strong>
              </div>
            </li> */}
              <li>
                <div className="sidebar-icon-item">
                  <i className="ri-speak-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Speaks</span>
                  <div className="d-float-center">
                    {data_path?.languages_spokens?.map(
                      (item: any, index: any) => {
                        return (
                          <>
                            <strong className="small-heading">
                              {item?.language?.name}
                              {index <
                                data_path?.languages_spokens?.length - 1 &&
                                ", "}
                            </strong>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="sidebar-icon-item">
                  <i className="ri-book-line"></i>
                </div>
                <div className="sidebar-text-info">
                  <span className="text-description">Education Level</span>
                  {data_path?.qualifications?.map((item: any, index: any) => {
                    return (
                      <>
                        <strong className="small-heading">
                          {item?.courseName}
                          {index < data_path?.qualifications?.length - 1 &&
                            ", "}
                        </strong>
                      </>
                    );
                  })}
                </div>
              </li> */}
            </ul>
          </div>
          <div className="sidebar-list-job">
            {/* <ul className="ul-disc font-sm">
              {data_path?.qualifications?.map((item: any, index: any) => {
                return (
                  <>
                    <li>
                      {item?.courseName}-{item?.institutionName},
                      <p>
                        {moment(item?.startDate).format("YYYY")}-
                        {moment(item?.endDate).format("YYYY")}
                      </p>
                      <p>{item?.description}</p>
                    </li>
                  </>
                );
              })}
            </ul> */}
            <div className="mt-0">
              {/* <ul>
                <li>Phone : {caregiver?.mobile}</li>
                <li>Email : {caregiver?.email}</li>
              </ul> */}
              <Button
                className="btn btn-send-message mt-0 find-giver-chat-btn"
                onClick={() => setOpenModal(true)}
              >
                Send Message
              </Button>
            </div>
          </div>
        </div>
        <div className="sidebar-border-bg bg-right single-giver-ads-section">
          <span className="text-grey">WE ARE</span>
          <span className="text-hiring">HIRING</span>
          <p className="font-xxs color-text-paragraph mt-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae
            architecto
          </p>
          <div className="mt-15">
            <a className="btn btn-paragraph-2" href="#">
              Know More
            </a>
          </div>
        </div>
      </div>
      {openmodal && <ChatRoom setOpenModal={setOpenModal} />}
    </>
  );
}
