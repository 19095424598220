import * as Yup from "yup";

// validation schema
export const ContactValidation = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address") // This ensures the email is in a valid format
    .required("Field is required")
    .nullable(),
  mobile: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must be numeric") // Ensures the mobile number contains only digits
    .required("Field is required")
    .nullable(),
  name: Yup.string().required("Field is required").nullable(),
  //   company: Yup.string().required("Field is required").nullable(),
  message: Yup.string().required("Field is required").nullable(),
  //   subscribe: Yup.string().required("Field is required").nullable(),
});

// initial values
export const initializeContactInitialValues = {
  email: "",
  mobile: "",
  name: "",
  company: "",
  message: "",
  subscribe: "",
};
