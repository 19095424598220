import { Link } from "react-router-dom";

export default function NewsBanner() {
  return (
    <>
      <section className="section-box">
        <div className="breacrumb-cover bg-img-about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="mb-10">News</h2>
              </div>
              <div className="col-lg-6 text-lg-end">
                <ul className="breadcrumbs mt-40">
                  <li>
                    <Link className="home-icon" to="/">
                      Home
                    </Link>
                  </li>
                  <li>News</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
