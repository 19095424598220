import React, { useEffect, useState } from "react";

const WebSocketComponent = () => {
  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState("");
  const [ws, setWs] = useState<any>(null);

  useEffect(() => {
    // Create a new WebSocket connection
    const socket: any = new WebSocket("https://echo.websocket.org/.ws");
    // console.log(socket?.onmessage,"socketsocket")

    // Event listener for receiving messages from the server
    socket.onmessage = (event: any) => {
      setMessages((prevMessages: any) => [...prevMessages, event.data]);
    };

    // Event listener for handling connection opening
    socket.onopen = () => {
      // console.log("WebSocket connection established.");
    };

    // Event listener for handling connection closing
    socket.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    // Event listener for handling errors
    socket.onerror = (error: any) => {
      console.error("WebSocket error:", error);
    };

    setWs(socket);

    // Clean up WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  }, []);
  const [loading, setLoding] = useState(false);
  // Send a message to the server
  const sendMessage = () => {
    if (ws && input.trim()) {
      ws.send(input);
      setInput(""); // Clear input field after sending
      setLoding(false);
    }
  };

  return (
    <div>
      <h2>WebSocket in React</h2>

      <div className="messages">
        {messages?.map((msg: any, index: any) => (
          <div key={index}>{msg}</div>
        ))}
      </div>

      <input
        type="text"
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
          setLoding(true);
        }}
        placeholder="Type a message..."
      />
      <button onClick={sendMessage}>Send Message</button>
    </div>
  );
};

export default WebSocketComponent;
