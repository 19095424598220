import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import constants from "../../../Utils/Constants/Constants";
import ModalContainer from "../../Modal/ModalContainer";

export default function Header() {
  const location = useLocation();
  const currentPathname = location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [isStateTrue, setIsStateTrue] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <style>
        {`
          .header.stick {
            padding: 14px 0px;
          }
        `}
      </style>

      <header className={`header sticky-bar ${isSticky ? "stick" : ""}`}>
        <div className="container">
          <div className="main-header top-header mb-3">
            <div className="header-contact">
              <a href="tel:1300 052 00">
                <i className="ri-phone-fill"></i> 1300 052 00
              </a>
              <span className="verical-line01">|</span>
              <a href="mailto:connect@ustaffings.com">
                <i className="ri-mail-fill"></i> connect@ustaffings.com
              </a>
            </div>
          </div>
          <hr />
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                <a className="d-flex" href="/">
                  <img
                    className="uss-logo01"
                    src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
                    alt="USS Logo"
                  />
                </a>
              </div>
            </div>
            <div className="header-nav">
              <nav className="nav-main-menu">
                <ul className="main-menu">
                  <li className="menu-nav-li">
                    <Link
                      className={currentPathname === "/" ? "active" : ""}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="menu-nav-li">
                    <Link
                      className={
                        currentPathname === "/about-us" ? "active" : ""
                      }
                      to="/about-us"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="menu-nav-li">
                    <Link
                      className={
                        currentPathname === "/services" ? "active" : ""
                      }
                      to="/services"
                    >
                      Services
                    </Link>
                  </li>
                  <li className="menu-nav-li">
                    <Link
                      className={
                        currentPathname === "/how-it-work" ? "active" : ""
                      }
                      to="/how-it-work"
                    >
                      How it Works
                    </Link>
                  </li>
                  <li className="menu-nav-li">
                    <Link
                      className={currentPathname === "/contact" ? "active" : ""}
                      to="/contact"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
              {isOpen === false && (
                <>
                  {" "}
                  <div
                    className="burger-icon burger-icon-white"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </>
              )}
            </div>
            <div className="header-right">
              <div className="block-signin">
                {currentPathname === "/careseeker-create-account" ||
                currentPathname === "/caregiver-create-account" ||
                currentPathname === "/create-profile-caregiver" ||
                currentPathname === "/create-profile-careseeker" ||
                currentPathname === "/create-profile-careseeker" ||
                currentPathname === "/create-profile-careseeker" ||
                currentPathname === "/create-profile-careseeker" ? null : (
                  <Link
                    className="btn btn-default btn-shadow ml-40 hover-up"
                    to="/register"
                  >
                    Register
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Responsive Menu */}
      {isOpen && (
        <div
          className="mobile-header-active mobile-header-wrapper-styles"
          onClick={() => setIsOpen(false)}
        >
          <div className="mobile-header-wrapper-inner">
            <div className="mobile-header-content-area">
              <div className="perfect-scroll">
                <div className="mobile-search mobile-header-border mb-30">
                  <form action="#">
                    <input type="text" placeholder="Search…" />
                    <i className="ri-search-line" />
                  </form>
                </div>
                <div className="mobile-menu-wrap mobile-header-border">
                  {/* mobile menu start */}
                  <nav>
                    <ul className="mobile-menu font-heading">
                      <li className="menu-nav-li">
                        <Link
                          className={currentPathname === "/" ? "active" : ""}
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="menu-nav-li">
                        <Link
                          className={
                            currentPathname === "/about-us" ? "active" : ""
                          }
                          to="/about-us"
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="menu-nav-li">
                        <Link
                          className={
                            currentPathname === "/services" ? "active" : ""
                          }
                          to="/services"
                        >
                          Services
                        </Link>
                      </li>
                      <li className="menu-nav-li">
                        <Link
                          className={
                            currentPathname === "/register" ? "active" : ""
                          }
                          to="/register"
                        >
                          How it Works
                        </Link>
                      </li>
                      <li className="menu-nav-li">
                        <Link
                          className={
                            currentPathname === "/contact" ? "active" : ""
                          }
                          to="/contact"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {currentPathname === "/careseeker-create-account" ||
                currentPathname === "/caregiver-create-account" ||
                currentPathname === "/create-profile-caregiver" ||
                currentPathname === "/create-profile-careseeker" ||
                currentPathname === "/create-profile-careseeker" ||
                currentPathname === "/create-profile-careseeker" ||
                currentPathname === "/create-profile-careseeker" ? null : (
                  <>
                    <div className="mobile-account">
                      <h6 className="mb-10">Your Account</h6>
                      <ul className="mobile-menu font-heading">
                        <li>
                          <Link to="/register">Register</Link>
                        </li>
                      </ul>
                      <div
                        className="burger-icon burger-icon-white burger-close"
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="burger-icon-top"></span>
                        <span className="burger-icon-mid"></span>
                        <span className="burger-icon-bottom"></span>
                      </div>
                    </div>
                  </>
                )}

                <div className="site-copyright mt-4">Copyright 2024 © USS.</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Mobile Responsive Menu */}
    </>
  );
}
