import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { modalPropType } from "../../Types";
import constants from "../../Utils/Constants/Constants";

const ModalContainers = ({
  toggle,
  isOpen,
  onClosed,
  titl,
  modalBodyClassName,
  children,
  size,
}: any) => {
  return (
    <>
      <Modal
        backdrop={onClosed}
        isOpen={isOpen}
        // centered
        toggle={toggle}
        onClosed={onClosed}
        size={size}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalContainers;
