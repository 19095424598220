import React from "react";

const FAQPage = () => {
  return (
    <>
      <section className="section-box">
        <div className="breacrumb-cover bg-img-about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="mb-10">Privacy Policy</h2>
              </div>
              {/* <div className="col-lg-6 text-lg-end">
                <ul className="breadcrumbs mt-40">
                  <li>
                    <a className="home-icon" href="#">
                      Home
                    </a>
                  </li>
                  <li>About Us</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-50 faq-company01">
        <div className="post-loop-grid">
          <div className="container">
          <div className="privacy-policy">
              <h6 className="f-18 color-text-mutted text-uppercase">
                Our 
              </h6>
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                Privacy Policy
              </h2>
              <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              A Privacy Policy is a legally-required agreement between you and the users of your website/app. 
              This agreement discloses your privacy practices and how you handle your users' personal data.
              </p>
              <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              For example, a Privacy Policy will describe the types of personal data you collect, how you collect the data, 
              how you keep it safe, what you use it for, and if you share any of that personal information with other parties.
              </p>
              <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              Privacy Policies also disclose the use of cookies and other tracking technologies that may affect user privacy.
              </p>              
              <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
              Privacy Policies are legally required by global privacy laws if you collect or use personal information.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const FAQItem = ({ id, headingId, question, answer }: any) => (
  <div className="panel panel-default">
    <div className="panel-heading" role="tab" id={headingId}>
      <h4 className="panel-title">
        <a
          className="collapsed"
          role="button"
          data-toggle="collapse"
          data-parent={`#${headingId}`}
          href={`#${id}`}
          aria-expanded="true"
          aria-controls={id}
        >
          {question}
        </a>
      </h4>
    </div>
    <div
      id={id}
      className="panel-collapse collapse"
      role="tabpanel"
      aria-labelledby={headingId}
    >
      <div className="panel-body">{answer}</div>
    </div>
  </div>
);

export default FAQPage;
