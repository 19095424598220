import CareGiver from "./Containers/CareGiver";
import CareSeeker from "./Containers/CareSeeker";
import "../../../Styles/responsive.css";
import { useState } from "react";
import ModalContainer from "../../../components/Modal/ModalContainer";


export default function HowitIndex() {
  const [selected, setSelected] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const _conteinerSelected = () => {};
  return (
    <>
      <main className="main">
        <div className="authincation h-100">
          <div className="container h-100">
            <div className="section px-5 pt-4 pb-4 mb-3 container">
              <div className="row">
                <CareSeeker
                  setSelected={setSelected}
                  selected={selected}
                  _conteinerSelected={_conteinerSelected}
                  setIsOpen={setIsOpen}
                />
                <CareGiver
                  setSelected={setSelected}
                  selected={selected}
                  _conteinerSelected={_conteinerSelected}
                  setIsOpen={setIsOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* <ModalContainer isOpen={isOpen} setIsopen={setIsOpen}>
        <RegisterModal />
      </ModalContainer> */}
    </>
  );
}
