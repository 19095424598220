import News from "../Home/Containers/News";
import ContactBanner from "./Containers/Banner";
import ContactForm from "./Containers/ContactForm";
import GoogleMap from "./Containers/GoogleMap";

export default function ContactIndux() {
  return (
    <>
      <main className="main">
        <ContactBanner />        
        <ContactForm />
        <GoogleMap />
        {/* <News /> */}
      </main>
    </>
  );
}
