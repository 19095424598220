import { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import constants from "../../../../Utils/Constants/Constants";

export default function AboutusMainPage() {
  const [tab, setTab] = useState(1);
  const [id, setId] = useState<any>();
  const [open, setOpen] = useState<any>("1");

  const toggle = (id: string) => {
    if (open === id) {
      setOpen(undefined);
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <style>
        {`
          
        `}
      </style>

      <section className="section-box mt-50">
        <div className="post-loop-grid">
          <div className="container">
            <div className="about-company01 row">
              <div className="col-md-12">
                <h6 className="f-18 color-text-mutted text-uppercase">
                  Our company
                </h6>
                <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                  About Our Company
                </h2>
              </div>
              <div className="col-md-6">               
                <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
                  USS is a trusted provider of compassionate and skilled
                  caregivers dedicated to enhancing the lives of individuals in
                  need. Our mission is to deliver personalized care and support,
                  empowering clients to live independently and with dignity.
                </p>
                <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
                  With a deep understanding of the diverse needs of our clients,
                  we offer a range of services.
                </p>
                <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
                  Our team of carefully selected and trained caregivers is
                  passionate about delivering exceptional care, tailored to meet
                  the unique requirements of each client. We prioritize building
                  strong relationships, trust, and open communication to ensure
                  seamless support.
                </p>
                <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
                  At USS we believe in:
                </p>
                <ul className="mt-2 about-ul">
                  <li>
                    <span>Compassion:</span> Treating clients with kindness,
                    empathy, and respect
                  </li>
                  <li>
                    <span>Quality:</span> Delivering exceptional care and service
                  </li>
                  <li>
                    <span>Reliability:</span> Providing dependable and consistent
                    support
                  </li>
                  <li>
                    <span>Flexibility:</span> Adapting to changing needs and
                    circumstances
                  </li>
                </ul>
                <p className="mt-2 font-sm color-text-paragraph wow animate__animated animate__fadeInUp mx-auto">
                  Our commitment to excellence has earned us a reputation as a
                  trusted partner in care. Let us help you or your loved one live
                  life to the fullest. Contact us today to learn more about our
                  caregiver services.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={`${constants.IMAGE_PATH.IMAG_PATH}page/about/img-about2.png`}
                  alt="joxBox"
                />
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 about-tabs-div">
                <div className="about-left-div view-care-givers align-items-start">
                  <div className="box-nav-tabs">
                    <ul
                      className="nav nav-tabs mb-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <li className="nav-item">
                        <a 
                          href="#"
                          className={`nav-link icon-common w-100 ${tab === 1 ? "active" : ""}`}
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"                      
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                          onClick={() => setTab(1)}
                        >
                          Our Mission
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#"
                          className={`nav-link icon-common w-100 ${tab === 2 ? "active" : ""}`}
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"                      
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                          onClick={() => setTab(2)}
                        >
                          Our Vision
                        </a>
                      </li>
                      <li className="nav-item">
                        <a 
                          href="#"
                          className={`nav-link icon-common w-100 ${tab === 3 ? "active" : ""}`}
                          id="v-pills-messages-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"                      
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                          onClick={() => setTab(3)}
                        >
                          Core Values
                        </a>                    
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="v-pills-tabContent">
                    {tab === 1 && (
                      <>
                        {" "}
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <img
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/about/img-about2.png`}
                                alt="joxBox"
                              />
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                              <h3 className="mb-2 mt-0">Our Mission</h3>
                              <div className="mt-20">
                                <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp mt-2">
                                  Our mission is to deliver compassionate,
                                  personalized care and support services that
                                  empower individuals and families to live with
                                  independence, dignity, and hope. We strive to
                                  build trusted relationships, foster inclusive
                                  communities, and make a positive impact on the
                                  lives of those we serve.
                                </p>
                              </div>
                              {/* <div class="mt-30"><a class="btn btn-brand-1" href="#">Read More</a></div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {tab === 2 && (
                      <>
                        {" "}
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <img
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/about/img-about2.png`}
                                alt="joxBox"
                              />
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                              <h3 className="mb-2 mt-0">Our Vision</h3>
                              <div className="mt-20">
                                <p className="font-sm color-text-paragraph mt-2">
                                  Our vision is to be the leading provider of
                                  comprehensive care and support services,
                                  renowned for our commitment to excellence,
                                  innovation, and compassion. We envision a
                                  future where every individual has access to
                                  quality care, support, and resources, enabling
                                  them to thrive and reach their full potential.
                                </p>
                              </div>
                              {/* <div class="mt-30"><a class="btn btn-brand-1" href="#">Read More</a></div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {tab === 3 && (
                      <>
                        {" "}
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <img
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/about/img-about2.png`}
                                alt="joxBox"
                              />
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                              <h3 className="mb-2 mt-0">Core Values</h3>
                              <div className="mt-20 core-value">
                                <ul className="mt-2 about-ul">
                                  <li>
                                    <span>Compassion:</span> We care deeply
                                    about the well-being of those we serve.
                                  </li>
                                  <li>
                                    <span>Respect:</span> We value diversity,
                                    inclusivity, and individuality.
                                  </li>
                                  <li>
                                    <span>Excellence:</span> We strive for
                                    exceptional quality in all we do.
                                  </li>
                                  <li>
                                    <span>Innovation:</span> We embrace
                                    creativity and forward-thinking solutions.
                                  </li>
                                  <li>
                                    <span>Trust:</span> We build strong
                                    relationships through reliability and
                                    transparency.
                                  </li>
                                  <li>
                                    <span>Empowerment:</span> We enable
                                    individuals to take control of their lives
                                    and make informed choices.
                                  </li>
                                </ul>
                              </div>
                              {/* <div class="mt-30"><a class="btn btn-brand-1" href="#">Read More</a></div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}                    
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 about-tabs-div mt-30">
                              <h3 className="mb-2 mt-0">What We Do?</h3>
                              <div className="mt-20">
                                <p className="font-sm color-text-paragraph mt-2">
                                  At USS, we offer a wide range of services
                                  designed to support individuals and families
                                  across various aspects of their lives. Our
                                  team of skilled professionals is dedicated to
                                  delivering personalized care and expertise in
                                  the following areas:
                                </p>
                                <Accordion toggle={toggle} open={open} className="accordion-about mt-3">
                                  <AccordionItem>
                                    <AccordionHeader
                                      targetId="1"
                                      className="accordion-header"
                                    >
                                      Health and Wellness
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1">
                                      <ul className="mt-2 about-ul">
                                        <li>
                                          <span>Occupational Therapy:</span>{" "}
                                          Helping individuals develop skills for
                                          daily living and independence
                                        </li>
                                        <li>
                                          <span>Physiotherapy:</span> Providing
                                          rehabilitation and treatment for
                                          physical injuries and conditions
                                        </li>
                                        <li>
                                          <span>Speech Pathology:</span>{" "}
                                          Assisting with communication and
                                          swallowing disorders
                                        </li>
                                        <li>
                                          <span>Audiology:</span> Diagnosing and
                                          treating hearing and balance disorders
                                        </li>
                                        <li>
                                          <span>Podiatry:</span> Caring for foot
                                          and ankle health
                                        </li>
                                        <li>
                                          <span>Nutrition and Dietetics:</span>{" "}
                                          Offering personalized nutrition advice
                                          and planning
                                        </li>
                                        <li>
                                          <span>Exercise Physiology:</span>{" "}
                                          Creating exercise programs for health
                                          and wellness
                                        </li>
                                      </ul>
                                    </AccordionBody>
                                  </AccordionItem>
                                  <AccordionItem>
                                    <AccordionHeader targetId="2">
                                      Mental Health and Counselling
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                      <ul className="mt-2 about-ul">
                                        <li>
                                          <span>Counselling:</span> Providing
                                          emotional support and guidance for
                                          mental health
                                        </li>
                                        <li>
                                          <span>Psychology:</span> Offering
                                          assessments, therapy, and counseling
                                          for mental health
                                        </li>
                                        <li>
                                          <span>Art Therapy:</span> Using
                                          creative expression for emotional
                                          healing
                                        </li>
                                        <li>
                                          <span>Music Therapy:</span> Utilizing
                                          music for emotional expression and
                                          healing
                                        </li>
                                        <li>
                                          <span>Sexual Assault Support:</span>{" "}
                                          Providing confidential support and
                                          counseling for survivors
                                        </li>
                                      </ul>
                                    </AccordionBody>
                                  </AccordionItem>
                                  <AccordionItem>
                                    <AccordionHeader targetId="3">
                                      Support and Care
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                      <ul className="mt-2 about-ul">
                                        <li>
                                          <span>Domestic Worker Support:</span>{" "}
                                          Assisting with daily living tasks and
                                          household management
                                        </li>
                                        <li>
                                          <span>Support Worker:</span> Providing
                                          personal care and support for daily
                                          living
                                        </li>
                                        <li>
                                          <span>Child Care/Baby Sitter:</span>{" "}
                                          Offering trustworthy childcare
                                          services
                                        </li>
                                        <li>
                                          <span>Support Coordination:</span>{" "}
                                          Connecting individuals with community
                                          resources and services
                                        </li>
                                        <li>
                                          <span>Behaviour Practitioner:</span>{" "}
                                          Supporting individuals with behavioral
                                          challenges
                                        </li>
                                        <li>
                                          <span>Social Work:</span> Providing
                                          guidance and support for social and
                                          emotional well-being
                                        </li>
                                        <li>
                                          <span>Welfare Work:</span> Offering
                                          assistance with daily living and
                                          connecting individuals with resources
                                        </li>
                                      </ul>
                                    </AccordionBody>
                                  </AccordionItem>

                                  <AccordionItem>
                                    <AccordionHeader targetId="4">
                                      Allied Health
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4">
                                      <ul className="mt-2 about-ul">
                                        <li>
                                          <span>
                                            Orthotics &amp; Prosthetics:
                                          </span>{" "}
                                          Creating custom devices for mobility
                                          and independence
                                        </li>
                                        <li>
                                          <span>Orthoptics:</span> Diagnosing
                                          and treating eye movement disorders
                                        </li>
                                        <li>
                                          <span>Diversional Therapy:</span>{" "}
                                          Using activities to enhance well-being
                                          and engagement
                                        </li>
                                        <li>
                                          <span>Child Life Therapy:</span>{" "}
                                          Supporting children's emotional and
                                          social development
                                        </li>
                                      </ul>
                                    </AccordionBody>
                                  </AccordionItem>
                                  <AccordionItem>
                                    <AccordionHeader targetId="5">
                                      Medical Services
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5">
                                      <ul className="mt-2 about-ul">
                                        <li>
                                          <span>Registered Nursing:</span>{" "}
                                          Delivering high-quality nursing care
                                          in various settings
                                        </li>
                                        <li>
                                          <span>
                                            Medical Radiation Therapy:
                                          </span>{" "}
                                          Providing radiation treatment for
                                          cancer and other conditions
                                        </li>
                                        <li>
                                          <span>Radiography:</span> Conducting
                                          diagnostic imaging tests
                                        </li>
                                        <li>
                                          <span>
                                            Nuclear Medicine Technology:
                                          </span>{" "}
                                          Using small amounts of radiation for
                                          diagnosis and treatment
                                        </li>
                                        <li>
                                          <span>Pharmacy:</span> Dispensing
                                          medications and providing expert
                                          advice
                                        </li>
                                        <li>
                                          <span>Genetic Counselling:</span>{" "}
                                          Offering guidance on genetic disorders
                                          and testinge
                                        </li>
                                      </ul>
                                    </AccordionBody>
                                  </AccordionItem>

                                  {/* Add more AccordionItems here as needed */}
                                </Accordion>
                                <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp mt-2">
                                  Our team is committed to delivering
                                  high-quality, personalized services that cater
                                  to the unique needs of each individual. We
                                  strive to make a positive impact on people's
                                  lives, empowering them to live independently
                                  and with dignity.
                                </p>
                              </div>
                              {/* <div class="mt-30"><a class="btn btn-brand-1" href="#">Read More</a></div> */}
                            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
