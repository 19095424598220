import constants from "../../../../Utils/Constants/Constants";

export default function SomeWords() {
  return <><section className="section-box mt-40 testimonials-section d-none">
  <div className="section-box wow animate__animated animate__fadeIn">
    <div className="container">
      <div className="text-start">
        <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">See Some Words</h2>
        <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">Thousands of care seekers get their support<br className="d-none d-lg-block"/>and feed back to us!</p>
      </div>
      <div className="box-swiper mt-50">
        <div className="swiper-container swiper-group-4-border swiper">
          <div className="swiper-wrapper pb-70 pt-5">



            <div className="swiper-slide hover-up">
              <div className="card-review-1">
              <div className="image-review"> <img src={`${constants.IMAGE_PATH.IMAG_PATH}testimonial/168144651197820.png`} alt=""/></div>
               
                <div className="review-info">
                  <div className="review-name">
                    <h5>Vargheese</h5><span className="font-xs">Manager Operations</span>
                  </div>
                  <div className="review-rating text-center">



                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  </div>
                  <div className="review-comment">Whether you’re shopping for the best type of hi...</div>
                </div>
              </div>
            </div>




            
            <div className="swiper-slide hover-up">
              <div className="card-review-1">
              <div className="image-review"> <img src={`${constants.IMAGE_PATH.IMAG_PATH}testimonial/168144651197820.png`} alt=""/></div>
             
                <div className="review-info">
                  <div className="review-name">
                    <h5>Varma KV</h5><span className="font-xs">Manager</span>
                  </div>
                  <div className="review-rating text-center">



                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  </div>
                  <div className="review-comment">Customer testimonials are a beneficial type of ...</div>
                </div>
              </div>
            </div>




            
            <div className="swiper-slide hover-up">
              <div className="card-review-1">
              <div className="image-review"> <img src={`${constants.IMAGE_PATH.IMAG_PATH}testimonial/168144651197820.png`} alt=""/></div>
              
                <div className="review-info">
                  <div className="review-name">
                    <h5>James kurian</h5><span className="font-xs">Manager</span>
                  </div>
                  <div className="review-rating text-center">



                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  </div>
                  <div className="review-comment">Customer testimonials are a powerful tool that ...</div>
                </div>
              </div>
            </div>




            
            <div className="swiper-slide hover-up">
              <div className="card-review-1">
              <div className="image-review"> <img src={`${constants.IMAGE_PATH.IMAG_PATH}testimonial/168144651197820.png`} alt=""/></div>
               
                <div className="review-info">
                  <div className="review-name">
                    <h5>Jony v</h5><span className="font-xs">Sales manager</span>
                  </div>
                  <div className="review-rating text-center">



                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  <img src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/star.svg`} alt=""/>
                  
                  </div>
                  <div className="review-comment">A testimonial is effectively a review or recomm...</div>
                </div>
              </div>
            </div>

 </div>
          <div className="swiper-pagination swiper-pagination-style-border"></div>
        </div>
      </div>
    </div>
  </div>
</section></>;
}
