import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { truncateText } from "../../../../Utils/helpers/helpers";

export default function News() {
  const sliderRef = useRef<any>(null);
  const settings: any = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    // nextArrow: true,
    // prevArrow: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="section-box mt-50 mb-50">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              News and Events
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Get the latest news, updates and tips
            </p>
          </div>
        </div>
        <div className="container">
          <div className="mt-50 home-news-events">
            <div className="box-swiper style-nav-top">
              <div className="swiper-container swiper-group-3 swiper">
                <div className="swiper-wrapper pb-70 pt-5 row">
                  <Slider {...settings} ref={sliderRef}>
                    <div className="col-md-12 px-3">
                      <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                        <div className="text-center card-grid-3-image">
                          <a href="#">
                            <figure>
                              <img
                                alt=""
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/recognising.png`}
                              />
                            </figure>
                          </a>
                        </div>
                        <div className="card-block-info">
                          <div className="tags mb-15">
                            <a className="btn btn-tag" href="#">
                              News
                            </a>
                          </div>
                          <h5
                            title="COVID-19 emergency leave extended for permanent aged care residents."
                            className="news-events-title"
                          >
                            <a href="#">
                              Recognising our aged care volunteers.
                            </a>
                          </h5>
                          <p className="mt-10 color-text-paragraph font-sm">
                            {truncateText(
                              ` We recognise and celebrates our dedicated aged care
                            volunteers today on International Volunteer Day.`,
                              100
                            )}
                          </p>
                          <div className="card-2-bottom mt-20">
                            <div className="row">
                              <div className="col-lg-6 col-6">
                                <div className="d-flex">
                                  <img
                                    className="img-rounded"
                                    src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/admin.png`}
                                    alt=""
                                  />
                                  <div className="info-right-img">
                                    <span className="font-sm font-bold color-brand-1 op-70">
                                      Admin
                                    </span>
                                    <br />
                                    <span className="font-xs color-text-paragraph-2">
                                      31 Dec 2023
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 text-end col-6 pt-15">
                                <span className="color-text-paragraph-2 font-xs">
                                  8 mins to read
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 px-3">
                      <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                        <div className="text-center card-grid-3-image">
                          <a href="#">
                            <figure>
                              <img
                                alt=""
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/aged-care.jpg`}
                              />
                            </figure>
                          </a>
                        </div>
                        <div className="card-block-info">
                          <div className="tags mb-15">
                            <a className="btn btn-tag" href="#">
                              News
                            </a>
                          </div>
                          <h5
                            title="COVID-19 emergency leave extended for permanent aged care residents."
                            className="news-events-title"
                          >
                            <a href="#">
                              Aged care design ideas competition now open.
                            </a>
                          </h5>
                          <p className="mt-10 color-text-paragraph font-sm">
                            {truncateText(
                              `The Reimagining Where We Live design ideas
                            competition is now open for architects and designers
                            to design an innovative aged care home and showcase
                            what can be achieved with the Australian
                            Government’s new Design Principles and Guidelines
                            for aged care homes.`,
                              100
                            )}
                          </p>
                          <div className="card-2-bottom mt-20">
                            <div className="row">
                              <div className="col-lg-6 col-6">
                                <div className="d-flex">
                                  <img
                                    className="img-rounded"
                                    src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/admin.png`}
                                    alt=""
                                  />
                                  <div className="info-right-img">
                                    <span className="font-sm font-bold color-brand-1 op-70">
                                      Admin
                                    </span>
                                    <br />
                                    <span className="font-xs color-text-paragraph-2">
                                      31 Dec 2023
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 text-end col-6 pt-15">
                                <span className="color-text-paragraph-2 font-xs">
                                  8 mins to read
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 px-3">
                      <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                        <div className="text-center card-grid-3-image">
                          <a href="#">
                            <figure>
                              <img
                                alt=""
                                src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/covid-19.jpg`}
                              />
                            </figure>
                          </a>
                        </div>
                        <div className="card-block-info">
                          <div className="tags mb-15">
                            <a className="btn btn-tag" href="#">
                              News
                            </a>
                          </div>
                          <h5
                            title="COVID-19 emergency leave extended for permanent aged care residents."
                            className="news-events-title"
                          >
                            <a href="#">
                              COVID-19 emergency leave extended for permanent
                              aged care residents.
                            </a>
                          </h5>
                          <p className="mt-10 color-text-paragraph font-sm">
                            {truncateText(
                              `We have extended emergency leave for permanent aged
                            care residents for 12 months. This means residents
                            who wish to temporarily relocate during COVID-19
                            outbreaks can continue to use emergency leave
                            instead of social leave through to 31 December 2024.`,
                              100
                            )}
                          </p>
                          <div className="card-2-bottom mt-20">
                            <div className="row">
                              <div className="col-lg-6 col-6">
                                <div className="d-flex">
                                  <img
                                    className="img-rounded"
                                    src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/newsevents/admin.png`}
                                    alt=""
                                  />
                                  <div className="info-right-img">
                                    <span className="font-sm font-bold color-brand-1 op-70">
                                      Admin
                                    </span>
                                    <br />
                                    <span className="font-xs color-text-paragraph-2">
                                      31 Dec 2023
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 text-end col-6 pt-15">
                                <span className="color-text-paragraph-2 font-xs">
                                  8 mins to read
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
              <div
                className="swiper-button-next"
                onClick={() => sliderRef.current.slickNext()}
              ></div>
              <div
                className="swiper-button-prev"
                onClick={() => sliderRef.current.slickPrev()}
              ></div>
            </div>
            <div className="text-center">
              <Link className="btn btn-brand-1 mt--30 hover-up" to="/news">
                View all <i className="ri-arrow-right-line"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
