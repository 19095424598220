import { useRef, useState } from "react";
import ModalContainers from "../../../../../components/Modal/ModalContainers";
import OtpModal from "./OTPMoadal";
import SuccessModal from "./SuccessCancelModal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import moment from "moment";
import { EmailClickHandler, OTpEmailClickHandler } from "./SubmitHandlers";
import { calculateHoursDifference } from "../../../../../Utils/helpers/helpers";
import { Col, Input, Row } from "reactstrap";

const validationSchema = Yup.object().shape({
  otp: Yup.array()
    .required("OTP is required")
    .length(4, "Please enter OTP")
    .test(
      "validate-digits",
      "Each OTP field must contain exactly 1 digit",
      function (value) {
        if (value && value.length === 4) {
          return value.every((val) => /^[0-9]$/.test(val));
        }
        return true; // Skip individual validation if length is not 5
      }
    ),
});

export default function OTPConformModal({
  isClose_1,
  amount,
  values_,
  service_providers,
  caregiver,
  email,
  data_full_
}: any) {
  const [centred, setCentered] = useState(false);
  const [all_data, setALlData] = useState([]);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => {
    setCentered(!centred);
  };
  const [value, setValue] = useState<any>();
  const [centred_, setCentered_] = useState(false);
  const centeredToggle_ = () => setCentered_(!centred_);
  const onCloseModal_ = () => {
    setCentered_(!centred_);
  };
  const [data_full, setDataFull] = useState([]);

  const inputRefs = useRef<any>([]);

  const handleChange = (
    e: { target: { value: any } },
    index: number,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean
      ): Promise<void | FormikErrors<{ otp: string[] }>>;
      (arg0: string, arg1: any): void;
    }
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      // Ensure only a single digit is entered
      setFieldValue(`otp[${index}]`, value);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    e: { key: string; target: { value: string } },
    index: number,
    setFieldValue: any
  ) => {
    if (e.key === "Backspace") {
      if (e.target.value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        setFieldValue(`otp[${index}]`, "");
      }
    }
  };
  console.log(data_full_, "minnu");
  return (
    <>
      <div className="slect-time-slots confirm-booking-slot">
        {/* <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">Select your time slot</h5>
          <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i className="ri-close-fill"></i></span>
          </button>
        </div> */}
        <div className="modal-body px-4 py-4">
          <div className="text-center pt-4">
            <h5 className="mb-4" style={{ fontSize: 50 }}>
              <i className="ri-checkbox-circle-fill text-success" />
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="success-message text-center">
                <h4 className="show-title mb-3">Time slot available</h4>
                <p className="show-date-time date">
                  {moment().format("dddd")},
                  {moment(values_?.start_date).format("DD MMM YYYY")}
                </p>
                <p className="show-date-time time">
                  {moment(values_?.start_time).format("hh:mm A")} -{" "}
                  {moment(values_?.end_time).format("hh:mm A")}{" "}
                  <span>
                    (
                    {calculateHoursDifference(
                      moment(values_?.start_time).format("hh:mm"),
                      moment(values_?.end_time).format("hh:mm")
                    )}{" "}
                    hr)
                  </span>
                </p>
                <p className="show-content hour-rate">
                  <span className="color-brand-2">${amount}</span> is estimated.
                </p>
              </div>
              <hr />
              <Formik
                initialValues={{
                  otp: ["", "", "", ""], // Array for OTP input
                }} // Initial form values
                validationSchema={validationSchema} // Apply validation schema
                onSubmit={(values) => {
                  OTpEmailClickHandler(
                    values,
                    setDataFull,
                    setCentered,
                    setCentered_,
                    setValue,
                    data_full_
                  );

                  // centeredToggle();
                }}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <h6 className="uss-text- text-center">
                            <span className="uss-text-primary">
                              {" "}
                              Please enter the one-time password we just sent to{" "}
                              {email}
                            </span>
                          </h6>
                          <small className="form-text text-muted col-md-12 mb-2">
                            Enter OTP
                          </small>
                          <Row>
                            {Array(4)
                              .fill("")
                              .map((_, index) => (
                                <Col lg="3">
                                  {" "}
                                  <Field
                                    key={index}
                                    name={`otp[${index}]`}
                                    as={Input}
                                    className="m-2 text-center form-control otp-field"
                                    maxLength={1}
                                    innerRef={(el: any) =>
                                      (inputRefs.current[index] = el)
                                    }
                                    onChange={(e: { target: { value: any } }) =>
                                      handleChange(e, index, setFieldValue)
                                    }
                                    onKeyDown={(e: {
                                      key: string;
                                      target: { value: string };
                                    }) =>
                                      handleKeyDown(e, index, setFieldValue)
                                    }
                                  />
                                </Col>
                              ))}
                          </Row>
                          <ErrorMessage
                            name={`otp`}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="d-float-center justify-content-center mt-3 mb-3">
                      <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={isClose_1} // Close the modal
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-default">
                        Verify
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {centred && (
          <>
            {" "}
            <ModalContainers
              toggle={centeredToggle}
              isOpen={centred}
              onClosed={onCloseModal}
              titl="Sucess"
              modalBodyClassName={""}
              children={
                <OtpModal
                  isClose_2={centeredToggle}
                  isClose_1={isClose_1}
                  data_full={data_full}
                  email={email}
                  service_providers={service_providers}
                  values_={values_}
                  amount={amount}
                  caregiver={caregiver}
                />
              }
              size={"lg"}
              backdrop
            />
          </>
        )}
        {centred_ && (
          <>
            {" "}
            <ModalContainers
              toggle={centeredToggle_}
              isOpen={centred_}
              onClosed={onCloseModal_}
              titl="Sucess"
              modalBodyClassName={""}
              children={
                <SuccessModal
                  isClose={centeredToggle_}
                  value={value}
                  setValue={setValue}
                  caregiver={caregiver}
                />
              }
              size={"lg"}
              backdrop
            />
          </>
        )}
      </div>
    </>
  );
}
