import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import { calculateAge, dateConvertion } from "../../../Utils/helpers/helpers";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
  declare: Yup.bool().oneOf([true], "You must accept the declaration."),
});

const Step4 = ({ setStep, Userdata, UserLoading_data }: any) => {
  const initialValues = {
    declare:
      Userdata?.user_details?.registrationConfirmationDeclaration === 1
        ? true
        : false,
  };
  const navigate = useNavigate();
  let actionType = ""; // Track button click

  const handleButtonClick = (type: string) => {
    actionType = type; // Track which button is clicked
  };
  const ProfileSubmitHandler = () => {
    const formData = new FormData();
    formData.append("step", "4");
    const set_: any = true;

    // formData.append("registration_confirmation_declaration", set_);
    formData.append("register_status", "save");
    const seeker_id: any = localStorage?.getItem("relatedCareseekerId");
    if (Userdata?.careseeker_details?.supportFor?.id!== 1) {
      formData.append("related_careseeker_id", seeker_id);
    }
    axios({
      method: "post",
      url: `${BaseUrl}/user/careseeker_complete_registration`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response: any) {
        if (response?.data?.status === true) {
          Swal.fire({
            title: "Success",
            text: "Details added successfully",
            icon: "success",
          });
          // toast.success("Detiles added successfully");
          // navigate("/success-careseeker?profile_completed=1");
          // localStorage.removeItem("AUTH_TOKEN");
        }
      })
      .catch(function (response) {
        if (response?.response?.data?.errors) {
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected Error Occurred",
            icon: "error",
          });
          // toast.error("Unexpected Error Occurred");
        }
      });
  };
  const ProfileCompleteHandler = () => {
    const formData = new FormData();
    formData.append("step", "4");
    const set_: any = true;

    formData.append("registration_confirmation_declaration", set_);
    const seeker_id: any = localStorage?.getItem("relatedCareseekerId");
    if (Userdata?.careseeker_details?.supportFor?.id !== 1) {
      formData.append("related_careseeker_id", seeker_id);
    }

    formData.append("register_status", "complete");
    axios({
      method: "post",
      url: `${BaseUrl}/user/careseeker_complete_registration`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    })
      .then(function (response: any) {
        if (response?.data?.status === true) {
          Swal.fire({
            title: "Success",
            text: "Details added successfully",
            icon: "success",
          });
          // toast.success("Detiles added successfully");
          navigate("/success-careseeker?profile_completed=1");
          localStorage.removeItem("AUTH_TOKEN");
          localStorage.removeItem("relatedCareseekerId");
        }
      })
      .catch(function (response) {
        if (response?.response?.data?.errors) {
          // setValidationErrors(response?.response?.data?.errors, actions);
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected Error Occurred",
            icon: "error",
          });
          // toast.error("Unexpected Error Occurred");
        }
      });
  };
  return (
    <>
      {UserLoading_data ? (
        <Skeleton count={12} />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (actionType === "save") {
              ProfileSubmitHandler();
            } else if (actionType === "complete") {
              ProfileCompleteHandler();
            }
            setSubmitting(false); // Stop submission spinner
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            isSubmitting,
          }) => (
            <Form className="seeker-registration-form4" onSubmit={handleSubmit}>
              <div className="text-center mb-3 logo-div01">
                <h4 className="block-subTitle text-center ">
                  {/* <span className="uss-text-primary">Create</span> Account */}
                  CONFIRM ALL DETAILS
                </h4>
              </div>

              <Card>
                <CardBody>
                  {" "}
                  <h6 className="text-end">
                    {" "}
                    <a
                      href="#"
                      onClick={() => {
                        // navigate("/create-profile-careseeker/1");
                        setStep(1);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <i className="ri-edit-box-line"></i> Edit
                    </a>
                  </h6>
                  <Row>
                    <Col lg={4}>
                      <Label>First Name </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">{Userdata?.firstName}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Last Name </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">{Userdata?.lastName}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Email Address</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put text-lowercase">
                        {Userdata?.email}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Phone Number </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">{Userdata?.mobile}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Age & DOB </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {calculateAge(Userdata?.careseeker_details?.dob)},&nbsp;
                        {dateConvertion(Userdata?.careseeker_details?.dob)}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Street Number </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.streetNumber}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Address line 1 </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.addressLineOne}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Address line 2</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.addressLineTwo}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>State </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.state?.state_name}(
                        {Userdata?.careseeker_details?.state?.state_code})
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Subrub </Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.suburb?.locality}(
                        {Userdata?.careseeker_details?.suburb?.postcode})
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Gender</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      <span className="out-put">
                        {Userdata?.careseeker_details?.gender?.id === 1
                          ? "Male"
                          : "Female"}
                      </span>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <h6 className="text-end">
                      {" "}
                      <a
                        href="#"
                        onClick={() => {
                          // navigate("/create-profile-careseeker/3");
                          setStep(3);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <i className="ri-edit-box-line"></i> Edit
                      </a>
                    </h6>
                    <Col lg={4}>
                      <Label>Profile Title</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.profileTitle}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Bio Description</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put">
                        {Userdata?.careseeker_details?.bioDescription}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label> Health concerns or Medical conditions</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      {" "}
                      <span className="out-put ">
                        {
                          Userdata?.careseeker_details
                            ?.healthConcernsOrMedicalConditions
                        }
                      </span>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <h6 className="text-end">
                      {" "}
                      <a
                        href="#"
                        onClick={() => {
                          // navigate("/create-profile-careseeker/2");
                          setStep(2);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <i className="ri-edit-box-line"></i> Edit
                      </a>
                    </h6>

                    <Col lg={4}>
                      <Label>Payment mode</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      <span className="out-put">
                        {Userdata?.careseeker_details?.paymentMode?.name}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Label>Email address for sending invoice</Label>
                      <span className="float-right">:</span>
                    </Col>
                    <Col lg={8}>
                      <span className="out-put text-lowercase">
                        {
                          Userdata?.careseeker_details
                            ?.emailAddressForSendingInvoice
                        }
                      </span>
                    </Col>
                  </Row>
                  {Userdata?.careseeker_details?.paymentMode?.id !== 2 &&
                  Userdata?.careseeker_details?.paymentMode?.id !== 7 ? (
                    <>
                      <Row>
                        <Col lg={4}>
                          <Label>Plan manger name</Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          <span className="out-put">
                            {Userdata?.careseeker_details?.planManagerName}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>NDIS ID</Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          <span className="out-put">
                            {Userdata?.careseeker_details?.ndisId}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label>Contact name</Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          <span className="out-put">
                            {Userdata?.careseeker_details?.ndisContactName}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label> Contact number</Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          <span className="out-put">
                            {Userdata?.careseeker_details?.ndisContactNumber}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4}>
                          <Label> Contact email</Label>
                          <span className="float-right">:</span>
                        </Col>
                        <Col lg={8}>
                          <span className="out-put">
                            {Userdata?.careseeker_details?.ndisContactEmail}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  <hr />
                  <FormGroup>
                    <Field
                      type="checkbox"
                      name="declare"
                      className="custom-checkbox"
                    />
                    <Label className="px-1 text-math-auto">
                      I hereby declare that the information provided is true and
                      accurate to the best of my knowledge.
                    </Label>
                    <ErrorMessage
                      name="declare"
                      component="div"
                      className="text-danger"
                    />
                  </FormGroup>
                  <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                    <Button
                      className="btn btn-brand-2"
                      type="submit"
                      onClick={() => {
                        // navigate("/create-profile-careseeker/3");
                        setStep(3);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <i className="ri-arrow-left-line"></i>&nbsp;BACK
                    </Button>
                    <Button
                      className="btn btn-success text-uppercase"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => handleButtonClick("save")}
                    >
                      {isSubmitting && <Spinner size="sm"></Spinner>}
                      Save
                    </Button>
                    <Button
                      className="btn btn-brand-1"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => handleButtonClick("complete")}
                    >
                      {isSubmitting && <Spinner size="sm"></Spinner>}
                      Complete
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default Step4;
