import { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import ModalContainers from "../../../../../components/Modal/ModalContainers";
import ConformModal from "./ConfirmModal";
import DatePickerReact from "../../../../../components/DatePicker/DatePickerReact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { max } from "moment";
import { getAvalibletIme } from "../../../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AmountClickHandler } from "./SubmitHandlers";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SlotModal({
  isClose,
  caregiver,
  service_providers,
}: any) {
  const initialValues = {
    timeFrom: null,
    timeTo: null,
  };
  const validationSchema = Yup.object().shape({
    timeFrom: Yup.date()
      .nullable()
      .required("Time From is required")
      .test(
        "is-valid-time",
        "Time From must be before Time To",
        function (value) {
          const { timeTo } = this.parent;
          return !value || !timeTo || value < timeTo;
        }
      ),
    timeTo: Yup.date()
      .nullable()
      .required("Time To is required")
      .test(
        "is-valid-time",
        "Time To must be after Time From",
        function (value: any) {
          const { timeFrom } = this.parent;
          return !value || !timeFrom || value > timeFrom;
        }
      ),
  });

  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);
  const onCloseModal = () => setCentered(!centred);

  const timeSlotArray: any = [
    { id: 1, time: "08:00 AM - 10:00 AM" },
    { id: 2, time: "06:30 PM - 07:30 PM" },
  ];

  const formatTimeSlots = (timeSlots: any) => {
    return timeSlots.flatMap((slot: any) => {
      const [start, end] = slot.time.split(" - ");
      return [
        moment(start, "hh:mm A").toDate(),
        moment(end, "hh:mm A").toDate(),
      ];
    });
  };

  const [date_select, setDateSelect] = useState(new Date());

  const availableTimes = formatTimeSlots(timeSlotArray);
  const [amount, setAmount] = useState<any>(0);
  const [all_avli, setAllVali] = useState(false);
  const { data: date_time, isLoading: date_timeLoading }: any = useQuery(
    [`date_time`, date_select, all_avli],
    () =>
      getAvalibletIme(
        moment(date_select ? date_select : new Date()).format("YYYY-MM-DD"),
        caregiver,
        all_avli
      ),
    {
      // keepPreviousData: true,
      staleTime: 1000,
    }
  );

  const [isDisabled, setIsDisabled] = useState(true); // Control this based on your logic
  // Group by day

  console.log(amount, "amount", amount);
  const [values, setValues] = useState<any>({
    start_date: "",
    start_time: "",
    end_time: "",
  });

  const handleSubmit = (values: any) => {
    console.log("Form submitted with values:", values);
    // centeredToggle();
    AmountClickHandler(values, setAmount, caregiver, date_select, setCentered);
    setValues({
      ...values,
      start_date: date_select,
      start_time: values?.timeFrom,
      end_time: values?.timeTo,
    });
  };
  const [end_date, setENd_date] = useState<any>();
  const givenDate = (date: any) => {
    const givenDate_ = moment(date, "YYYY-MM-DD");

    // Add 6 days to the given date
    const resultDate = givenDate_.add(6, "days");

    // Format the new date
    const formattedDate: any = moment(resultDate).format("DD MMM YYYY"); // '13/11/2024'
    console.log(resultDate, "resultDate");
    setENd_date(formattedDate);
  };
  function getTimesBetween(startTime: any, endTime: any) {
    console.log(startTime, "startTime",endTime);
    const times = [];

    let start = new Date(`1970-01-01T${startTime}`);
    let end = new Date(`1970-01-01T${endTime}`);
console.log(end,start,"anu")
    // Loop through the time range and push times to the array
    while (start <= end) {
      times.push(start.toTimeString().substring(0, 5)); // Format time as HH:MM
      start.setMinutes(start.getMinutes() + 30); // Increment by 30 minutes
    }

    return times;
  }
  useEffect(() => {
    if (all_avli === true) {
      findLarge();
    }
  }, [all_avli]);
  const [max_min, setmax] = useState({ max: "", min: "" });
  const findLarge = () => {
    let earliestTime: any = null;
    let latestTime: any = null;

    // Loop through the data to find the earliest and latest times
    if (date_time?.length !== 0) {
      date_time?.forEach((item: any) => {
        const startTime = new Date(`1970-01-01T${item.start_time}`); // Use a consistent date format
        const endTime = new Date(`1970-01-01T${item.end_time}`); // Same for end time

        if (earliestTime === null || startTime < earliestTime) {
          earliestTime = startTime; // Update the earliest time if a new earlier time is found
        }
        if (latestTime === null || endTime > latestTime) {
          latestTime = endTime; // Update the latest time if a new later time is found
        }
      });
      const earliestTimeString = moment(earliestTime).format("HH:mm:ss");
      const latestTimeString = moment(latestTime).format("HH:mm:ss");
      setmax({
        ...max_min,
        max: latestTimeString,
        min: earliestTimeString,
      });
      console.log("Earliest time:", earliestTimeString);
      console.log("Latest time:", latestTimeString);
    }
  };

  // Format the results back to time strings if needed

  const startTime = date_time?.start_time
    ? date_time?.start_time
    : max_min?.min; // example start time
  const endTime = date_time?.end_time ? date_time?.end_time : max_min?.max; // example end time
  const result = getTimesBetween(startTime, endTime);
  console.log(result, "max_min");
  return (
    <>
      <style>{`label {
  font-weight: 600;
  color: #555;
}
`}</style>
      <div className="slect-time-slots">
        <div className="modal-header">
          <h5 className="modal-title">Select your time slot</h5>
          <button type="button" className="close" onClick={() => isClose()}>
            <span aria-hidden="true">
              <i className="ri-close-fill"></i>
            </span>
          </button>
        </div>
        <div className="modal-body p-4">
          <div className="calender-time-right row">
            <div className="mb-3 col-md-12">
              <label className="d-block">Select Date</label>
              <DatePickerReact
                selected={date_select}
                onChange={(date: any) => {
                  setDateSelect(date);
                  givenDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText="DD/MM/YYYY"
                className="form-control"
                showIcon
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="modal-body p-4 pt-0">
                <div className="calender-time-right row">
                  <div className="available-box mb-3">
                    <h6>
                      {all_avli === true ? (
                        <>
                          Availability between{" "}
                          {moment(date_select).format("DD MMM YYYY")}(
                          {moment(date_select).format("dddd")}) and {end_date}(
                          {moment(end_date).format("dddd")})
                        </>
                      ) : (
                        <>
                          {" "}
                          Availability on{" "}
                          {moment(date_select).format("DD MMM YYYY")}(
                          {moment(date_select).format("dddd")}){" "}
                        </>
                      )}
                    </h6>
                    <hr />
                    <div className="mb-0 col-md-12">
                      {/* <label className="d-block "><Input type="checkbox" className=""/><span className="px-2">Veiw All Availability</span> </label>
                       */}
                      <Input
                        type="checkbox"
                        name="all_avli"
                        checked={all_avli} // Controlled component
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAllVali(e.target.checked);
                          givenDate(date_select ? date_select : new Date());
                        }}
                      />{" "}
                      &nbsp;
                      <Label className="mb-0">View All Days</Label>
                    </div>
                    <div className="row">
                      <div className="col-6">Day</div>
                      <div className="col-6">Time</div>
                    </div>
                    {date_timeLoading ? (
                      <Skeleton count={7} />
                    ) : (
                      <>
                        {all_avli === false ? (
                          <div className="availability-row row soft-green p-2 mb-0">
                            <div className="col-6">
                              <span className="availability-day text-dark">
                                {date_time?.day}
                              </span>
                            </div>
                            <div className="col-6">
                              <a
                                className={`btn btn-available btn-sm ${
                                  date_time?.start_time
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {date_time?.start_time ? (
                                  <>
                                    {moment(
                                      date_time?.start_time,
                                      "HH:mm"
                                    ).format("hh:mm A")}{" "}
                                    -{" "}
                                    {moment(
                                      date_time?.end_time,
                                      "HH:mm"
                                    ).format("hh:mm A")}
                                  </>
                                ) : (
                                  "Unavailable"
                                )}
                              </a>
                            </div>
                          </div>
                        ) : (
                          <>
                            {date_time?.map((item: any, index: any) => {
                              return (
                                <>
                                  {" "}
                                  <div className="availability-row row soft-green p-2 mb-0">
                                    <div className="col-6">
                                      <span className="availability-day text-dark">
                                        {item?.day}
                                      </span>
                                    </div>
                                    <div className="col-6">
                                      <a
                                        className={`btn btn-available btn-sm ${
                                          item?.start_time
                                            ? "text-success"
                                            : "text-danger"
                                        }`}
                                      >
                                        {item?.start_time ? (
                                          <>
                                            {moment(
                                              item?.start_time,
                                              "HH:mm"
                                            ).format("hh:mm A")}{" "}
                                            -{" "}
                                            {moment(
                                              item?.end_time,
                                              "HH:mm"
                                            ).format("hh:mm A")}
                                          </>
                                        ) : (
                                          "Unavailable"
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {/* <div className="unavailable-box my-3">
                    <div className="soft-grey p-0">
                      <p className="mb-2">
                        <span className="red-point text-dark">
                          Unavailable Slots (Already booked)
                        </span>
                      </p>
                      <div className="day-comment d-float-center gap-15">
                        <a className="btn btn-unavailable mr-5 mt-2">
                          08:00 AM - 10:00 AM
                        </a>
                        <a className="btn btn-unavailable mr-5 mt-2">
                          01:00 PM - 02:00 PM
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-6 mb-3">
                    <label>Time From</label>
                    <Field name="timeFrom">
                      {({ field }: any) => (
                        <DatePicker
                          selected={field.value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select a time"
                          className="px-4"
                          onChange={(date) => setFieldValue("timeFrom", date)}
                          // onKeyDown={(e) => {
                          //   // Prevent typing if the input should be disabled
                          //   if (isDisabled) {
                          //     e.preventDefault();
                          //   }
                          // }}
                          showIcon
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                            </svg>
                          }
                          includeTimes={result?.map(
                            (item) => new Date(`1970-01-01T${item}`)
                          )}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="timeFrom"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Time To</label>
                    <Field name="timeTo">
                      {({ field }: any) => (
                        <DatePicker
                          selected={field.value}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select a time"
                          className="px-4"
                          onChange={(date) => setFieldValue("timeTo", date)}
                          // onKeyDown={(e) => {
                          //   // Prevent typing if the input should be disabled
                          //   if (isDisabled) {
                          //     e.preventDefault();
                          //   }
                          // }}
                          showIcon
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13 12V7H11V14H17V12H13Z"></path>
                            </svg>
                          }
                          includeTimes={result?.map(
                            (item) => new Date(`1970-01-01T${item}`)
                          )}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="timeTo"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={isClose}
                  >
                    <i className="ri-close-fill"></i> Cancel
                  </button>
                  <button type="submit" className="btn btn-default">
                    Continue <i className="ri-arrow-right-line"></i>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {centred && (
        <ModalContainers
          toggle={centeredToggle}
          isOpen={centred}
          onClosed={onCloseModal}
          titl="Success"
          modalBodyClassName=""
          children={
            <ConformModal
              isClose_1={centeredToggle}
              amount={amount}
              values={values}
              service_providers={service_providers}
              caregiver={caregiver}
            />
          }
          size="lg"
          backdrop
        />
      )}
    </>
  );
}
