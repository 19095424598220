import { Link } from "react-router-dom";
import constants from "../../../Utils/Constants/Constants";

export default function CommingSoon() {
  return (
    <>
      <style>
        {` 

        `}
      </style>
      <div className="how-it-work">
        <section className="section-box">
            <div className="breacrumb-cover bg-img-about">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <h2 className="mb-10">How it works</h2>
                    {/* <p className="font-lg color-text-paragraph-2">Get the latest news, updates and tips</p> */}
                </div>
                {/* <div className="col-lg-6 text-lg-end">
                    <ul className="breadcrumbs mt-40">
                    <li><a className="home-icon" href="#">Home</a></li>
                    <li>How it work</li>
                    </ul>
                </div> */}
                </div>
            </div>
            </div>
        </section>
        <section className="section-box mt-50 horizontal-flow">
            <div className="post-loop-grid">
            <div className="container">
                <div className="uk-container uk-padding">
                <div className="uk-timeline">
                    <div className="uk-timeline-item">
                    <div className="uk-timeline-icon">
                        <span className="uk-badge"><span uk-icon="check">1</span></span>
                    </div>
                    <div className="uk-timeline-content">
                        <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                        <div className="uk-card-header">
                            <div className="uk-grid-small uk-flex-middle" uk-grid>
                            <h3 className="uk-card-title uss-primary">Step 1 : <strong className="text-dark">Register an account to start</strong></h3>
                            {/* <span className="uk-label uk-label-success uk-margin-auto-left">Register an account to start</span> */}
                            </div>
                        </div>
                        <div className="uk-card-body">
                            <p className="uk-text-success">Register Safely and Securely on our platform.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="uk-timeline-item">
                    <div className="uk-timeline-icon">
                        <span className="uk-badge"><span uk-icon="check">2</span></span>
                    </div>
                    <div className="uk-timeline-content">
                        <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                        <div className="uk-card-header">
                            <div className="uk-grid-small uk-flex-middle" uk-grid>
                            <h3 className="uk-card-title uss-primary">Step 2 : <strong className="text-dark">Explore over thousands of profiles</strong></h3>
                            {/* <span className="uk-label uk-label-warning uk-margin-auto-left">Explore over thousands of profiles</span> */}
                            </div>
                        </div>
                        <div className="uk-card-body">
                            <p>Find exactly the services that match your needs.</p>                            
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="uk-timeline-item">
                    <div className="uk-timeline-icon">
                        <span className="uk-badge"><span uk-icon="check">3</span></span>
                    </div>
                    <div className="uk-timeline-content">
                        <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                        <div className="uk-card-header">
                            <div className="uk-grid-small uk-flex-middle" uk-grid>
                            <h3 className="uk-card-title uss-primary">Step 3 : <strong className="text-dark">Find the most suitable support worker</strong></h3>
                            {/* <span className="uk-label uk-label-danger uk-margin-auto-left">Find the most suitable support worker</span> */}
                            </div>
                        </div>
                        <div className="uk-card-body">
                            <p>Find local workers who are most suited to your requirements.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className="section-box mt-50 mb-20">
            <div className="container">
            <div className="box-newsletter">
                <div className="row">
                <div className="col-xl-3 col-12 text-center d-none d-xl-block"><img src={`${constants.IMAGE_PATH.IMAG_PATH}template/newsletter-left.png`} alt="joxBox" /></div>
                <div className="col-lg-12 col-xl-6 col-12">
                    <h2 className="text-md-newsletter text-center">New Things Will Always<br /> Update Regularly</h2>
                    <div className="box-form-newsletter mt-40">
                    <form className="form-newsletter">
                        <input className="input-newsletter" type="text" placeholder="Enter your email here" />
                        <button className="btn btn-default font-heading icon-send-letter">Subscribe</button>
                    </form>
                    </div>
                </div>
                <div className="col-xl-3 col-12 text-center d-none d-xl-block"><img src={`${constants.IMAGE_PATH.IMAG_PATH}template/newsletter-right.png`} alt="joxBox" /></div>
                </div>
            </div>
            </div>
        </section>
        </div>

    </>
  );
}
