import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { BaseUrl } from "../../../../Api/BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getServiceProvides } from "../../../../Api/WebsiteApi";

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Form, Input } from "reactstrap";
import DatePickerReact from "../../../../components/DatePicker/DatePickerReact";

const validationSchema = Yup.object().shape({
  service_type: Yup.string().required("Service Type is required"),
  loaction: Yup.string().required("Location is required"),
});

export default function ServicesBanner({ dataListcount }: any) {
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [loaction, setLocation] = useState([]);
  const [erro, setErr] = useState<any>({
    loaction: "",
    // range: 5 || "",
    service_type: "",
    date: "",
  });
  const [values, setVlues] = useState<any>();

  useEffect(() => {
    const fetchLocations = async (searchTerm: string) => {
      try {
        const response = await axios.get(
          `${BaseUrl}/user/master/locations?search=${searchTerm}`
        );
        const new_array = response?.data?.data?.map((item: any) => ({
          value: item?.id,
          label: `${item?.locality}-${item?.postcode}`,
          label_only: item?.locality,
          state: item?.stateName,
        }));
        setLocation(new_array || []);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchLocations(values);
  }, [values]);
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none", // Remove border
      boxShadow: "none", // Remove shadow
      "&:hover": {
        border: "none", // Remove border on hover
      },
      opacity: 2.8,
      backgroundColor: "white",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999,
      opacity: 2.8, /// Ensure the dropdown is on top
      backgroundColor: state.isFocused ? "white" : "white",
    }),
  };
  const { data: dataList }: any = useQuery(
    [`services_providers`],
    () => getServiceProvides(),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  return (
    <>
      <style>
        {`
          .header.sticky-bar {
          box-shadow: none;
          }
          .range-km01 {
          width: 100%;
          }
        `}
      </style>
      <section className="section-box-2 services-list-banner mt-2">
        <div className="container">
          <div className="banner-hero banner-single banner-single-bg">
            <div className="block-banner text-center">
              <h3 className="wow animate__animated animate__fadeInUp">
                <span className="color-brand-2">{dataListcount} Services</span>{" "}
                Available Now
              </h3>
              <div
                className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                With USS, you are able to choose your services and also choose
                who provides your services. <br className="d-none d-xl-block" />
                Choose a service to get started.
              </div>

              <div
                className="form-find text-start mt-40 wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
              >
                <Formik
                  initialValues={{
                    service_type: "",
                    loaction: "",
                    range: 5,
                  }}
                  // validationSchema={validationSchema}
                  onSubmit={(values) => {
                    navigate(
                      `/find/caregivers?service_type_id=${
                        values.service_type ? values.service_type : ""
                      }&suburb_id=${
                        selectedLocation?.value ? selectedLocation?.value : ""
                      }&radius=${values.range ? values.range : ""}&subrub=${
                        selectedLocation?.label_only
                          ? selectedLocation?.label_only
                          : ""
                      }&state=${
                        selectedLocation?.state ? selectedLocation?.state : ""
                      }`
                    );
                  }}
                >
                  {({ setFieldValue, handleSubmit, errors, handleChange }) => {
                    return (
                      <>
                        {console.log(values, "aGHAGSAHS")}
                        <Form
                          className="service-list-form"
                          onSubmit={handleSubmit}
                        >
                          <div className="box-industry me-3">
                            <Field
                              as="select"
                              className="form-input mr-10 select-active input-industry"
                              name="service_type"
                            >
                              <option value="">Service Type</option>
                              {dataList?.map((item: any, index: any) => (
                                <option key={index} value={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="box-location">
                            <ReactSelect
                              name="loaction"
                              options={loaction}
                              onChange={(option: any) => {
                                setFieldValue(
                                  "loaction",
                                  option ? option.value : ""
                                );
                                setSelectedLocation(option);
                              }}
                              placeholder="Enter Location"
                              isClearable
                              styles={customStyles}
                              onInputChange={(inputValue: any) => {
                                setVlues(inputValue);
                              }}
                            />
                            {/* <ErrorMessage name="loaction" component="div" className="error-message" /> */}
                          </div>

                          {/* Range Slider */}
                          <div className="range-km01 mt-0">
                            <DatePickerReact
                              selected={
                                values?.date ? new Date(values?.date) : null
                              }
                              onChange={(date: any) =>
                                setVlues({
                                  ...values,
                                  date: date,
                                })
                              }
                              name="avlibility_date"
                              dateFormat="dd/MM/yyyy"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              placeholderText="Available Date"
                              className={`form-control`}
                              showIcon
                              minDate={new Date()}
                            />
                          </div>
                          <button
                            className="btn btn-default btn-find font-sm mt-0"
                            type="submit"
                            onClick={() => {
                              setErr({
                                ...erro,
                                service_type: errors?.service_type,
                                loaction: errors?.loaction,
                              });
                            }}
                          >
                            Search
                          </button>
                        </Form>
                      </>
                    );
                  }}
                </Formik>

                {(erro?.service_type || erro?.loaction) && (
                  <>
                    {" "}
                    <Alert color="danger" className="mt-30">
                      <ol>
                        <li>. {erro?.service_type}</li>
                        <li>. {erro?.loaction}</li>
                      </ol>
                    </Alert>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
