import { Card, CardBody, CardHeader, Container, FormGroup } from "reactstrap";
import constants from "../../../Utils/Constants/Constants";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  calculateAge,
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
  formatAmount,
  getURLPassData,
  stripePaymentStatus,
} from "../../../Utils/helpers/helpers";
import { useQuery } from "react-query";
import { getNDISData } from "../../../Api/WebsiteApi";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { BaseUrl } from "../../../Api/BaseUrl";
import { useNavigate } from "react-router-dom";
export default function NDISApproval() {
  const id: any = getURLPassData();
  // Initial values for the form
  const initialValues = {
    status: "",
    description: "",
  };
  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    description: Yup.string()
      .nullable()
      .when("status", {
        is: "2", // Comment says 'When status is false'
        then: (schema) => schema.required("Description is required "),
        otherwise: (schema) => schema.notRequired(),
      }),
  });
  const status_selection = [
    { value: 1, label: "Accept" },
    { value: 2, label: "Reject" },
  ];
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = (values: any, actions: any) => {
    console.log(values,"valuesvaluesvalues")
    Swal.fire({
      title:
        values?.status === "1"
          ? "Are you sure you want to accept this request?"
          : " Are you sure you want to reject this request?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
      icon: "warning",
    }).then((result) => {
      if (result?.isConfirmed) {
        console.log(values); // Handle form submission here
        const formData: any = new FormData();
        formData.append("id", id);
        formData.append("is_approved", values?.status === "1" ? true : false);
        formData.append("rejected_reason", values?.description);

        axios({
          method: "post",
          url: `${BaseUrl}/user/booking/ndis/request/approval`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          },
        })
          .then(function (response: any) {
            // console.log(response, "response");
            if (response?.data?.status === true) {
              Swal.fire({
                title: "Success!",
                text: "Changes done successfully",
                icon: "success",
              });
              actions?.setSubmitting(true);
              navigate("/NDIS-approval-success");

              // toast.success("Detiles added successfully");
            }
          })
          .catch(function (response) {
            if (response?.response?.data?.errors) {
              // setValidationErrors(response?.response?.data?.errors, actions);
            } else {
              Swal.fire({
                title: "Error!",
                text: response?.response?.data?.message,
                icon: "error",
              });
              actions?.setSubmitting(false);
            }
          });
      }
    });
  };

  const { data: dataList }: any = useQuery(
    [`getNDISData`, id],
    () => (id ? getNDISData(id) : ""),
    {
      keepPreviousData: true,
      staleTime: 1000,
    }
  );
  const formatTimeWithAMPM = (time24: any) => {
    if (time24) {
      const [hours, minutes] = time24?.split(":")?.map(Number);
      const suffix = hours >= 12 ? "PM" : "AM";
      const hours12 = hours % 12 || 12; // Convert 0 or 12-hour to 12 in AM/PM format
      return `${hours12}:${minutes.toString().padStart(2, "0")} ${suffix}`;
    }
  };
  return (
    <>
      <Container className="ndis-container my-4">
        <Card className="ndis-page">
          <CardHeader className="p-25">
            <table className="w-100 ndis-table ndis-head">
              <thead>
                <tr>
                  <th>
                    <h3 className="m-0">NDIS Details</h3>
                  </th>
                  <th>
                    <div className="header-logo">
                      <a className="logo-link" href="#">
                        <img
                          className="uss-logo01"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
                          alt="USS Logo"
                        />
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </CardHeader>
          <CardBody className="p-25">
            <table className="w-100 ndis-table ndis-body">
              <tbody>
                <tr>
                  <td className="pe-4" style={{ width: "65%" }}>
                    <div className="ndis-booking-info mt-1 mb-2">
                      <h5 className="text-dark mb-2">
                        {dataList?.booking_details?.bookingId}
                      </h5>
                      <p className="service-type">
                        <i className="ri-service-line"></i>&nbsp;
                        {dataList?.booking_details?.careseeker_required_services
                          ?.map((item: any) => item?.name)
                          .join(", ")}
                      </p>
                      <p>
                        <i className="ri-calendar-2-line"></i>&nbsp;
                        {moment(
                          dataList?.booking_details
                            ?.careseeker_required_service_days?.[0]?.startDate
                        ).format("DD MMM YYYY")}
                        ,&nbsp;
                        {formatTimeWithAMPM(
                          dataList?.booking_details
                            ?.careseeker_required_service_days?.[0]?.startTime
                        )}
                        &nbsp;-&nbsp;
                        {formatTimeWithAMPM(
                          dataList?.booking_details
                            ?.careseeker_required_service_days?.[0]?.endTime
                        )}
                      </p>
                      <p>
                        <i className="ri-navigation-line"></i>&nbsp;
                        {CapitalFirstLetter(
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.suburb?.locality
                        )}
                        &nbsp;-&nbsp;
                        {
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.suburb?.state
                        }{" "}
                        (
                        {
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.suburb?.postcode
                        }
                        )
                      </p>
                    </div>
                  </td>
                  <td style={{ width: "35%" }}>
                    <div className="ndis-booking-status mt-1 mb-2">
                      <h5 className="text-secondary mb-2">
                        <span className="input">Total Amount :</span>&nbsp;
                        <span className="uss-secondary output">
                          $
                          {formatAmount(dataList?.booking_details?.totalAmount)}
                        </span>
                      </h5>
                      {/* <p>
                        <i className="ri-hourglass-fill"></i>&nbsp;
                        <span className="input">Payment Status :</span>&nbsp;
                        <span className="text-danger output text-uppercase">
                          {stripePaymentStatus(dataList?.booking_details?.stripePaymentStatus)}
                        </span>
                      </p> */}
                      <p>
                        <i className="ri-time-line"></i>&nbsp;
                        <span className="input">Total Hour :</span>&nbsp;
                        <span className="text-dark output">2 Hour</span>
                      </p>
                      {/* <p>
                        <i className="ri-money-dollar-circle-line"></i>&nbsp;
                        <span className="input">Payment  :</span>&nbsp;
                        <span className="text-dark output">--</span>
                      </p> */}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr className="hr-main-ndis uss-secondary" />
                  </td>
                </tr>
                <tr>
                  <td className="pe-4" style={{ width: "65%" }}>
                    <div className="ndis-booking-info mt-2">
                      <h5 className="mb-2 box-head01">Caregiver Info</h5>
                      <p className="ps-2">
                        <i className="fa fa-user-md" aria-hidden="true"></i>
                        &nbsp;
                        {capitalizeFirstLetterOfEachWord(
                          dataList?.booking_details?.caregiver_details
                            ?.firstName
                        )}
                        &nbsp;
                        {capitalizeFirstLetterOfEachWord(
                          dataList?.booking_details?.caregiver_details?.lastName
                        )}
                      </p>
                      <p className="ps-2">
                        <i className="ri-information-line"></i>&nbsp;
                        {dataList?.booking_details?.caregiver_details
                          ?.user_details?.gender?.id === 1
                          ? "Male"
                          : dataList?.booking_details?.caregiver_details
                              ?.user_details?.gender?.id === 2
                          ? "Female"
                          : "-"}
                        ,{" "}
                        {calculateAge(
                          dataList?.booking_details?.caregiver_details
                            ?.user_details?.dob
                        )}
                      </p>
                      <p className="ps-2">
                        <i className="ri-map-pin-line"></i>&nbsp;
                        {CapitalFirstLetter(
                          dataList?.booking_details?.caregiver_details
                            ?.user_details?.suburb?.locality
                        )}
                        &nbsp;-&nbsp;
                        {
                          dataList?.booking_details?.caregiver_details
                            ?.user_details?.suburb?.state
                        }{" "}
                        (
                        {
                          dataList?.booking_details?.caregiver_details
                            ?.user_details?.suburb?.postcode
                        }
                        )
                      </p>
                    </div>
                  </td>
                  <td className="pe-0" style={{ width: "35%" }}>
                    <div className="ndis-booking-info mt-2">
                      <h5 className="mb-2 box-head01">Careseeker Info</h5>
                      <p className="ps-2">
                        <i className="fa fa-user" aria-hidden="true"></i>
                        &nbsp;
                        {capitalizeFirstLetterOfEachWord(
                          dataList?.booking_details?.careseeker_details
                            ?.firstName
                        )}
                        &nbsp;
                        {capitalizeFirstLetterOfEachWord(
                          dataList?.booking_details?.careseeker_details
                            ?.lastName
                        )}
                      </p>
                      <p className="ps-2">
                        <i className="ri-information-line"></i>&nbsp;
                        {dataList?.booking_details?.careseeker_details
                          ?.careseeker_details?.gender?.id === 1
                          ? "Male"
                          : dataList?.booking_details?.careseeker_details
                              ?.careseeker_details?.gender?.id === 2
                          ? "Female"
                          : "-"}
                        ,{" "}
                        {calculateAge(
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.dob
                        )}
                      </p>
                      <p className="ps-2">
                        <i className="ri-map-pin-line"></i>&nbsp;
                        {CapitalFirstLetter(
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.suburb?.locality
                        )}
                        &nbsp;-&nbsp;
                        {
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.suburb?.state
                        }{" "}
                        (
                        {
                          dataList?.booking_details?.careseeker_details
                            ?.careseeker_details?.suburb?.postcode
                        }
                        )
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="ndis-booking-info mt-4">
                      <h5 className="mb-2 box-head01">Booking Instructions</h5>
                      <p className="ps-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="ndis-booking-info mt-4">
                      <h5 className="mb-2 box-head01">Task List</h5>
                      <p className="ps-2 task-list">
                        <ul className="d-float-center gap-15">
                          <li>Wash Cloths</li>
                          <li>Remove Waste</li>
                          <li>Feed the Dog</li>
                          <li>Water Gardens</li>
                        </ul>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr className="hr-sub-main-ndis uss-secondary" />
                  </td>
                </tr>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema} // Apply the validation schema
                  onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                  }}
                >
                  {({
                    setFieldValue,
                    errors,
                    touched,
                    values,
                    handleSubmit,
                  }) => (
                    <>
                      {console.log(values?.status)}
                      <tr>
                        <td colSpan={2}>
                          <Form className="mt-4" onSubmit={handleSubmit}>
                            <FormGroup className="">
                              <label>Status</label>
                              <Field
                                as="select"
                                name="status"
                                className={`form-control ${
                                  errors.status && touched.status
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={(
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) => setFieldValue("status", e.target.value)}
                              >
                                <option value="">Select Status</option>
                                {status_selection?.map((item: any) => (
                                  <option key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </option>
                                ))}
                              </Field>
                              {/* Display error message for status */}
                              {errors.status && touched.status && (
                                <div className="invalid-feedback">
                                  {errors.status}
                                </div>
                              )}
                            </FormGroup>

                            {/* Description textarea */}
                            <FormGroup className="form-group">
                              <label>Reject Reason</label>
                              <Field
                                as="textarea"
                                name="description"
                                className={`form-control textarea ${
                                  errors.description && touched.description
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Type..."
                              />
                              {/* Display error message for description */}
                              {errors.description && touched.description && (
                                <div className="invalid-feedback">
                                  {errors.description}
                                </div>
                              )}
                            </FormGroup>

                            <hr className="hr-main-ndis uss-secondary" />

                            <div className="d-float-center justify-content-end my-0">
                              <button
                                // type="submit"
                                className="btn btn-default2"
                              >
                                <i className="ri-download-line"></i> Download
                                Pdf
                              </button>
                              {/* <button
                                    type="button"
                                    className="btn btn-outline-secondary me-2"
                                  >
                                    <i className="ri-close-line"></i> Cancel
                                  </button> */}
                              <button type="submit" className="btn btn-default">
                                {/* <i className="ri-share-forward-line"></i>{" "} */}
                                Submit
                              </button>
                            </div>
                          </Form>
                        </td>
                      </tr>
                    </>
                  )}
                </Formik>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
