import { Link } from "react-router-dom";
import constants from "../../../Utils/Constants/Constants";

export default function CommingSoon() {
  return (
    <>
      <style>
        {` .support-staff-finder {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sub-staff-finder {
    text-align: center;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #eee;
    padding: 75px 100px;
}
.sub-staff-finder .construction-img {
    width: 18mm;
}
.sub-staff-finder p {
  text-transform: uppercase;
    font-size: 20px;
    color: #000;
    margin-top: 15px;
}`}
      </style>
      <section className="section-box">
        <div className="breacrumb-cover bg-img-about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="mb-10">Coming Soon</h2>
              </div>
              <div className="col-lg-6 text-lg-end">
                <ul className="breadcrumbs mt-40">
                  <li>
                    <a className="home-icon" href="#">
                      Home
                    </a>
                  </li>
                  <li>Construction Page</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-box mt-50">
        <div className="post-loop-grid">
          <div className="container">
            <div className="construction-page">
              <div className="support-staff-finder">
                <div className="sub-staff-finder col-md-12">
                  <img
                    className="construction-img"
                    src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/construction.svg`}
                  />
                  <p className="text-danger">This Page</p>
                  <p style={{ marginTop: "10px" }}>Under Construction.</p>
                  <Link className="text-white btn btn-brand-1 mt-3" to="/">
                    {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                    <i className="ri-arrow-left-line"></i>
                    &nbsp;Back to home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
