/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, CardBody, CardHeader, Container, FormGroup } from "reactstrap";
import constants from "../../../Utils/Constants/Constants";

export default function NDISApprovalSuccess() {
  return (
    <>
      <Container className="ndis-container my-4">
        <Card className="ndis-page">
          <CardHeader className="p-25">
            <table className="w-100 ndis-table ndis-head">
              <thead>
                <tr>
                  <th>
                    <h3 className="m-0">NDIS Details</h3>
                  </th>
                  <th>
                    <div className="header-logo">
                      <a className="logo-link" href="#">
                        <img
                          className="uss-logo01"
                          src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
                          alt="USS Logo"
                        />
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </CardHeader>
          <CardBody className="p-25">
            <div className="col-md-12">
              <div className="text-center pt-3">
                <h5 className="mb-4" style={{ fontSize: 50 }}>
                  <i className="ri-checkbox-circle-fill text-success" />
                </h5>
              </div>
            </div>
            <div className="col-md-12">
              <div className="success-message text-center">
                <h4 className="show-title mb-3">
                  Thank you for the valuable response.{" "}
                </h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
