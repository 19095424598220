import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import {
  AuthChecker,
  setValidationErrors,
} from "../../../../../Utils/helpers/helpers";
import Swal from "sweetalert2";

export const RegisterSubmitHandler = (
  values: any,
  actions: any,
  navigate: any,
  setRegisterCode: Function,
  setOtpDisplay: Function,
  setErrorBackend: Function
) => {
  actions.setSubmitting(true);
  api
    .post("/user/caregiver_registration", values, false)
    .then(async function ([success, response]: any) {
      // console.log(response, "response?.data?.status");
      if (response?.status === true) {
        setRegisterCode(response?.registration_code);
        setOtpDisplay(2);
        actions.setSubmitting(false);
      }
      if (response?.errors) {
        actions.setSubmitting(false);
        // setValidationErrors(response?.errors, actions);
      }
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        // toast.error(err?.response?.data?.message, {
        //   toastId: "Invalid-login-details",
        // });
        setErrorBackend(err?.response?.data?.message);
      } else {
        // toast?.error("Unexpected error occurred", {
        //   toastId: "error-occurred",
        // });
        Swal.fire({
          title: "Error",
          text: "Unexpected error occurred",
          icon: "error",
        });
      }
    });
};
export const ResendSubmitHandler = (
  setRegisterCode: Function,
  setOtpDisplay: Function,
  setErrorBackend: Function,
  resend_values: any
) => {
  api
    .post("/user/caregiver_registration", resend_values, false)
    .then(async function ([success, response]: any) {
      // console.log(response, "response?.data?.status");
      if (response?.status === true) {
        Swal.fire({
          title: "Success",
          text: "OTP Send Successfully",
          icon: "success",
        });
        setRegisterCode(response?.registration_code);
        // setOtpDisplay(2);
      }
      if (response?.errors) {
      }
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        // toast.error(err?.response?.data?.message, {
        //   toastId: "Invalid-login-details",
        // });
        setErrorBackend(err?.response?.data?.message);
      } else {
        // toast?.error("Unexpected error occurred", {
        //   toastId: "error-occurred",
        // });
        Swal.fire({
          title: "Error",
          text: "Unexpected error occurred",
          icon: "error",
        });
      }
    });
};
export const OTPSubmitHandler = (
  values: any,
  actions: any,
  navigate: any,
  register_code: any,
  setOtpDisplay: Function,
  setCentered: any
) => {
  actions.setSubmitting(true);
  api
    .post(
      "/user/caregiver_registration/verify_otp",
      {
        otp: values.otp.join(""),
        registration_code: register_code,
      },
      false
    )
    .then(async function ([success, response]: any) {
      // console.log(response, "response?.data?.statusanu");
      if (response?.status === true) {
        Swal.fire({
          title: "Success",
          text: "OTP Verified",
          icon: "success",
        });
        localStorage.setItem("AUTH_TOKEN", response?.token);
        setCentered(true);
        // navigate("/success");
        actions.setSubmitting(false);
      }
      if (response?.status === false) {
        // toast?.error(response?.message, {
        //   toastId: "error-occurred",
        // });
        actions.setSubmitting(false);
      }
      // if (response?.errors) {
      //   actions.setSubmitting(false);
      //   setValidationErrors(response?.errors, actions);
      // }
    })
    .catch((err) => {
      // console.log(err, "jjjj");
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        // toast?.error(err?.response?.data?.messageerr?.response?.data?.message, {
        //   toastId: "error-occurred",
        // });
        Swal.fire({
          title: "Error",
          text: err?.response?.data?.message,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Unexpected error occurred",
          icon: "error",
        });
        // setValidationErrors(err, actions);
        // toast?.error("Unexpected error occurred", {
        //   toastId: "error-occurred",
        // });
      }
    });
};
