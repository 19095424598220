import React, { useState } from "react";
import "./Styles/style.css";
import "./Styles/custom-style.css";
import "./Styles/responsive.css";
import "./Styles/satoshi.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaList from "./Meta/Meta";
import { QueryClient, QueryClientProvider } from "react-query";
const App = () => {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          toastStyle={{
            backgroundColor: "#20c9c3", // Custom background color
            color: "#ffffff", // Custom text color
          }}
        />
        <MetaList />
      </QueryClientProvider>
    </>
  );
};

export default App;
