import React from "react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="errorpage">
        {/* <div classNameName="error-text-wrap text-center">
          <h2 classNameName="error-text">Oops!</h2>
          <h2 classNameName="error-text">Page Not Found</h2>
          <Button
            outline
            color="white"
            classNameName="sm my-3"
            onClick={() => navigate("/")}
          >
            Back to home
          </Button> */}
        {/* </div> */}
        <section className="pt-50 login-register">
        <div className="container">
          <div className="row login-register-cover pb-250">
            <div className="col-lg-5 col-md-6 col-sm-12 mx-auto">
              <div className="text-center"><img className="w-75 mb-30" src="https://jobbox-html-frontend.vercel.app/assets/imgs/template/404.svg" alt="JobBox"/>
              <h2 className="error-text">Oops!</h2>
              <h2 className="error-text">Page Not Found</h2>
              </div>
              
              <div className="text-center mt-20"><Button
            outline
            color="white"
            classNameName="sm my-3"
            onClick={() => navigate("/")}
          >
            Back to home
          </Button></div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default ErrorPage;
