import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from "formik";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { RATES } from "../../../Utils/Constants/select_options";
import { useNavigate } from "react-router-dom";
import {
  initialValues_5,
  validationSchema_5,
} from "../ProfileMethods/ProfileValidation";
import {
  addModalInitialValuesHandlerStep5,
  ProfileSubmitHandler5,
  ScrollToFieldError,
} from "../ProfileMethods/ProfileSubmitHandlers";

const Step5 = ({ setStep, Userdata, UserListRefetch }: any) => {
  const navigate = useNavigate();
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValues_5);

  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep5(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValues_5);
    }
  }, [Userdata, Userdata?.id]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addModalInitialValues}
        validationSchema={validationSchema_5}
        onSubmit={(values, actions) => {
          // console.log(values, "hjhxjasxasxha");
          ProfileSubmitHandler5(
            values,
            actions,
            navigate,
            setStep,
            UserListRefetch
          );
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldTouched,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <>
            {/* <ScrollToFieldError /> */}
              {/* {console.log(values?.rates, "values?.rates")} */}
              <Form onSubmit={handleSubmit}>
                <div className="text-center mb-3 logo-div01">
                  <h3 className="block-subTitle text-center uss-text-info">
                    {/* <span className="uss-text-primary">Create</span> Account */}
                  </h3>
                </div>
                <Card>
                  <CardBody>
                    <Label>Rates</Label>
                    <FieldArray name="rates">
                      {({ push, remove }) => (
                        <>
                          {values?.rates?.map((rate, index) => {
                            const minError = getIn(
                              errors,
                              `rates.${index}.min`
                            );
                            const minTouched = getIn(
                              touched,
                              `rates.${index}.min`
                            );
                            const maxError = getIn(
                              errors,
                              `rates.${index}.max`
                            );
                            const maxTouched = getIn(
                              touched,
                              `rates.${index}.max`
                            );

                            return (
                              <div key={rate.label} className="mb-3">
                                <Label>{rate.label}</Label>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-2">
                                      {/* <label htmlFor={`rates.${index}.min`}>
                                        Min
                                      </label> */}
                                      <InputGroup>
                                        <InputGroupText>$</InputGroupText>
                                        <Field
                                          id={`rates.${index}.min`}
                                          name={`rates.${index}.min`}
                                          type="text"
                                          className={`form-control ${
                                            minError && minTouched
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </InputGroup>
                                      {minError && minTouched && (
                                        <div className="text-danger">
                                          {minError}
                                        </div>
                                      )}
                                    </div>
                                  </Col>

                                  {/* <Col lg="5">
                                    <div className="mb-2">
                                      <label htmlFor={`rates.${index}.max`}>
                                        Max
                                      </label>
                                      <InputGroup>
                                        <InputGroupText>$</InputGroupText>
                                        <Field
                                          id={`rates.${index}.max`}
                                          name={`rates.${index}.max`}
                                          type="text"
                                          className={`form-control ${
                                            maxError && maxTouched
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </InputGroup>
                                      {maxError && maxTouched && (
                                        <div className="text-danger">
                                          {maxError}
                                        </div>
                                      )}
                                    </div>
                                  </Col> */}
                                </Row>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </FieldArray>

                    <FormGroup className="d-float-center" switch>
                      <div className="extra-hrs-switch">
                        {" "}
                        <Input
                          type="switch"
                          name="extra_charge_after_hours"
                          onChange={handleChange}
                          checked={values?.extra_charge_after_hours}
                          // value={values.extra_charge_after_hours}
                        />
                        <Label
                          className="mb-0 ms-2"
                          htmlFor="extra_charge_after_hours"
                        >
                          Extra 20% after hrs (6 PM to 8 PM)
                        </Label>
                      </div>

                      <ErrorMessage
                        name="extra_charge_after_hours"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </CardBody>
                </Card>

                <FormGroup className="mt-5">
                  <label className="mt-3" htmlFor="bank_name">
                    ABN
                  </label>
                  <Field
                    type="text"
                    name="abn"
                    className={`form-control ${
                      touched.abn && errors.abn ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.abn}
                  />
                  <ErrorMessage
                    name="abn"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup className="mt-5">
                  <label className="mt-3" htmlFor="company_name">
                    Company Name
                  </label>
                  <Field
                    type="text"
                    name="company_name"
                    className={`form-control ${
                      touched.company_name && errors.company_name
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}
                  />
                  <ErrorMessage
                    name="company_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="bank_name">Bank Name</label>
                  <Field
                    type="text"
                    name="bank_name"
                    className={`form-control ${
                      touched.bank_name && errors.bank_name ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_name}
                  />
                  <ErrorMessage
                    name="bank_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <FormGroup>
                  {" "}
                  <label htmlFor="bank_branch_name">BSB</label>
                  <Field
                    type="text"
                    name="bank_branch_name"
                    className={`form-control ${
                      touched.bank_branch_name && errors.bank_branch_name
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={(e: any) => {
                      let value = e.target.value.replace(/\D/g, ""); // Remove non-digits
                      if (value.length > 3) {
                        value = `${value.slice(0, 3)}-${value.slice(3, 6)}`; // Add the hyphen after the first 3 digits
                      }
                      setFieldValue("bank_branch_name", value); // Use Formik's setFieldValue to update the field
                    }}
                    onBlur={handleBlur}
                    value={values.bank_branch_name}
                  />
                  <ErrorMessage
                    name="bank_branch_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="bank_account_number">
                    Bank Account Number
                  </label>
                  <Field
                    type="text"
                    name="bank_account_number"
                    className={`form-control ${
                      touched.bank_account_number && errors.bank_account_number
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_account_number}
                  />
                  <ErrorMessage
                    name="bank_account_number"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {Object.keys(errors).length ===1 ? (
                  <>
                    {" "}
                    <div>
                      <Alert color="danger" className="mt-30" id="g">
                        Please make sure all fields are filled correctly.
                      </Alert>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="text-center d-float-center justify-content-center gap-15">
                  <Button
                    className="btn btn-brand-2"
                    type="submit"
                    onClick={() => [setStep(4)]}
                  >
                    <i className="ri-arrow-left-line"></i>&nbsp;BACK
                  </Button>
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default Step5;
