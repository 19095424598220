import React, { useRef } from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import { Button, Input, Row, Col, Card } from "reactstrap";
import { OTPSubmitHandlerCareSeeker } from "../RegisterMethods/CareSeekerMethods";

const OTPForm = ({
  phone_number,
  OtpValidationSchema,
  OTPSubmitHandler,
  navigate,
  register_code,
  setOtpDisplay,
  handleResendCode,
  isTimerActive,
  timer,
  resendCount,
  setCentered
}: any) => {
  const inputRefs = useRef<any>([]);

  const handleChange = (
    e: { target: { value: any } },
    index: number,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean
      ): Promise<void | FormikErrors<{ otp: string[] }>>;
      (arg0: string, arg1: any): void;
    }
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      // Ensure only a single digit is entered
      setFieldValue(`otp[${index}]`, value);

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    e: { key: string; target: { value: string } },
    index: number,
    setFieldValue: any
  ) => {
    if (e.key === "Backspace") {
      if (e.target.value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        setFieldValue(`otp[${index}]`, "");
      }
    }
  };

  return (
    <Row className="justify-content-center h-100 align-items-center">
      <Col md={6} className="login-page01">
        <div className="text-center mb-3 logo-div01 mt-30">
          <h3 className="block-subTitle text-center uss-text-info">
            <span className="uss-text-primary">Create</span> Account Care Seeker
          </h3>
        </div>
        <Card className="authincation-content card-body py-4">
          <div className="text-center mb-3 logo-div01">
            <h3 className="block-subTitle text-center uss-text-info">
              <span className="uss-text-primary">Verify OTP</span>
            </h3>
          </div>
          <h6 className="uss-text- text-center">
            Please enter the one-time password we just sent to{" "}
            {/* target div */}
            <span
              style={{
                color: "#53aeb2",
              }}
            >
              {phone_number ? phone_number : localStorage?.getItem("EMAIL")}
            </span>
            {/*  */}
            <br /> to verify your account
          </h6>
          <Formik
            initialValues={{ otp: ["", "", "", ""] }}
            validationSchema={OtpValidationSchema}
            onSubmit={(values, actions) => {
              const otpValue = values.otp.join("");
              // console.log("OTP Submitted:", otpValue);
              OTPSubmitHandlerCareSeeker(
                values,
                actions,
                navigate,
                register_code,
                setOtpDisplay,
                setCentered
              );
            }}
          >
            {({ handleSubmit, setFieldValue, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div
                  id="otp"
                  className="inputs d-flex flex-row justify-content-center mt-2 main_otp"
                >
                  {Array(4)
                    .fill("")
                    .map((_, index) => (
                      <Field
                        key={index}
                        name={`otp[${index}]`}
                        as={Input}
                        className="m-2 text-center form-control otp-field"
                        maxLength={1}
                        innerRef={(el: any) => (inputRefs.current[index] = el)}
                        onChange={(e: { target: { value: any } }) =>
                          handleChange(e, index, setFieldValue)
                        }
                        onKeyDown={(e: {
                          key: string;
                          target: { value: string };
                        }) => handleKeyDown(e, index, setFieldValue)}
                      />
                    ))}
                </div>
                <ErrorMessage
                  name="otp"
                  component="div"
                  className="text-danger text-center mt-2"
                />
                <div className="text-center mt-30">
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submit..." : "Submit"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Button
            color="link"
            onClick={handleResendCode}
            disabled={isTimerActive || resendCount >= 3}
            className="text-center"
          >
            {isTimerActive ? `Resend Code in ${timer}s` : "Resend SMS Code"}
          </Button>

          {resendCount >= 3 && (
            <div className="text-danger text-center">
              Maximum resend attempts reached.
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default OTPForm;
