import { useState } from "react";
import constants from "../../../Utils/Constants/Constants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import RegisterModal from "../../../Pages/Public/HowItWork/Containers/RegisterModal";

const Footer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigte = useNavigate();
  return (
    <>
      <style>
        {`          
          .grayscale-img {
            filter: grayscale(1);
          }
        `}
      </style>{" "}
      <footer className="footer mt-50">
        <div className="container">
          <div className="row">
            <div className="footer-col-01 col-md-5 col-sm-12">
              <a href="#">
                <img
                  className="footer-uss-logo01"
                  alt="USS"
                  src={`${constants.IMAGE_PATH.IMAG_PATH}template/uss-logo.png`}
                  width={120}
                />
              </a>
              <div className="mt-20 mb-20 font-xs color-text-paragraph-2">
                USS is the heart of the support worker community and the best
                resource to discover and connect with support workers in your
                community.
              </div>
              <div className="footer-social">
                <Link
                  className="icon-socials icon-facebook"
                  to={constants.FOOTER_SECTION_LINK.FACEBOOK}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/facebook.svg`}
                    alt=""
                  />
                  {""}
                </Link>
                <Link
                  className="icon-socials icon-instagram"
                  to={constants.FOOTER_SECTION_LINK.INSTARGRAM}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/insta.svg`}
                    alt=""
                  />
                </Link>
                {/* <a className="icon-socials icon-linkedin" href="#"></a> */}
              </div>
            </div>
            <div className="footer-col-02 col-md-2 col-xs-6">
              <h6 className="mb-20">Resources</h6>
              <ul className="menu-footer">
                <li>
                  <Link to={constants.FOOTER_SECTION_LINK.ABOUT_US}>
                    About us
                  </Link>
                </li>
                <li>
                  <Link to={constants.FOOTER_SECTION_LINK.HowItWOrk}>
                    How it Works
                  </Link>
                </li>
                <li>
                  <Link to={constants.FOOTER_SECTION_LINK.CONTACT}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col-03 col-md-2 col-xs-6">
              <h6 className="mb-20">Downloads</h6>
              <ul className="menu-footer">
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/terms-conditions">Terms & Conditions</a>
                </li>
                <li>
                  <Link to="/faq"> FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col-06 col-md-3 col-sm-12">
              <h6 className="mb-20">Download App</h6>
              <p className="color-text-paragraph-2 font-xs">
                Download our Apps and get extra 15% Discount on your first
                Booking!
              </p>
              <div className="mt-15">
                <Link to="https://www.apple.com/in/app-store/" className="me-2" target="-blank">
                  <img
                    src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/app-store.png`}
                    alt="App Store"
                    className="grayscale-img"
                  />
                </Link>

                <Link to="https://play.google.com/store/games?device=windows" target="-blank">
                  <img
                    src={`${constants.IMAGE_PATH.IMAG_PATH}template/icons/android.png`}
                    alt="Google Play Store"
                    className="grayscale-img"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-bottom mt-50">
            <div className="row">
              <div className="col-md-6">
                <span className="font-xs color-text-paragraph">
                  Copyright &copy; 2024. USS all right reserved
                </span>
              </div>
              <div className="col-md-6 text-md-end text-start">
                <div className="footer-social">
                  <a className="font-xs color-text-paragraph" href="/privacy-policy">
                    Privacy Policy
                  </a>
                  <a
                    className="font-xs color-text-paragraph mr-30 ml-30"
                    href="/terms-conditions"
                  >
                    Terms &amp; Conditions
                  </a>
                  {/* <a className="font-xs color-text-paragraph" href="#">
                    FAQ
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <ModalContainer isOpen={isOpen} setIsopen={setIsOpen}>
        <RegisterModal />
      </ModalContainer> */}
    </>
  );
};
export default Footer;
