// SelectComponent.tsx or SelectComponent.jsx
import { cC } from "@fullcalendar/core/internal-common";
import React, { useEffect, useRef } from "react";
import ReactSelect from "react-select";

interface SelectComponentProps {
  options: Array<{ value: string; label: string }>;
  value?: any;
  onChange: (selectedOption: any) => void;
  onBlur?: () => void;
  isClearable?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  error?: any;
  loading?: any;
  ref?: any;
  name?: any;
  closeMenuOnSelect?: any;
  hideSelectedOptions?: any;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  value,
  onChange,
  onBlur,
  isClearable = false,
  placeholder = "Select an option...",
  isMulti = false,
  error,
  loading = false,
  ref,
  name,
  closeMenuOnSelect,
  hideSelectedOptions,
}) => {
  return (
    <div>
      <ReactSelect
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isClearable={isClearable}
        placeholder={placeholder}
        isMulti={isMulti}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: "35px", // Custom height
            borderColor: error ? "red" : base.borderColor, // Show red border on error
            "&:hover": {
              borderColor: error ? "red" : base.borderColor,
            },
          }),
        }}
        isLoading={loading}
        ref={ref}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default SelectComponent;
