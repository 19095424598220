import NewsBanner from "./Containers/banners";
import NewsList from "./Containers/NewsList";

export default function NewsIndex() {
  return (
    <>
      <NewsBanner />
      <NewsList />
    </>
  );
}
