import { CapitalFirstLetter, capitalizeFirstLetterOfEachWord } from "../../../../Utils/helpers/helpers";

export default function ShortBio({
  view_dataExp,
  data_path,
  view_data,
  view_dataEdu,
  centeredToggle,
  
}: any) {
  return (
    <>
      {" "}
      <div className="tab-pane fade show active">
        {" "}
        <h4 className="mb-2">About Me</h4>
        <p>{capitalizeFirstLetterOfEachWord(data_path?.aboutYourself)}</p>
        <div className="row">
          <div className="col-md-6 sub-box-content">
            <div className="find-giver-view-sub-box mb-3">
              <h6 className="mt-0 mb-1 giver-sub-head">
                <i className="ri-map-pin-line"></i>&nbsp;Services Area
              </h6>
              <p className="d-float-center">
                <span className="m-0 mt-1 care-giver-sub-list">
                  <strong className="ms-1 mt-2">
                    {data_path?.serviceRadius} Kms around{" "}
                    {CapitalFirstLetter(data_path?.suburb?.locality)}&nbsp;-&nbsp;
                    {data_path?.suburb?.state}&nbsp;({data_path?.suburb?.postcode})
                  </strong>
                </span>
              </p>
            </div>
          </div>

          {view_data(
            "Services Provided",
            data_path?.service_provides,
            "serviceProvides",
            <i className="ri-service-line"></i>
          )}
          {view_data(
            "Aged Care Experience",
            data_path?.experience_with_aged_cares,
            "experienceWithAgedCare",
            <i className="ri-empathize-line"></i>
          )}
          {view_data(
            "Childcare Experience",
            data_path?.experience_with_children,
            "experienceWithChildren",
            <i className="ri-open-arm-line"></i>
          )}
          {view_data(
            "Willing To Work with",
            data_path?.willing_to_work,
            "willingToWork",
            <i className="ri-wheelchair-line"></i>
          )}

          {view_data(
            "Interests",
            data_path?.interests,
            "interest",
            <i className="ri-lightbulb-flash-line"></i>
          )}

          <div className="col-lg-12 col-md-4 book-now-btn-sec01">
            <p className="d-float-center justify-content-end">
              <a
                className="btn btn-default"
                href="#"
                onClick={() => centeredToggle()}
              >
                Book Now
              </a>
            </p>
          </div>
        </div>
        <hr />
        <h4>Work Experience</h4>
        {view_dataExp(data_path?.work_experiences)}
        <h4>Education</h4>
        {view_dataEdu(data_path?.qualifications)}
      </div>
    </>
  );
}
