import React, { useState, useEffect, useRef } from "react";
import { Button } from "reactstrap";

/* Helper function to detect URL and add anchor tags */
function detectURL(message: any) {
  var urlRegex: any = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function (urlMatch: any) {
    return `<a href="${urlMatch}" target="_blank">${urlMatch}</a>`;
  });
}

/* Title component */
const Title = ({ owner }: any) => {
  return <div className="chatApp__convTitle">{owner}'s display</div>;
};

/* InputMessage component */
const InputMessage = ({
  owner,
  ownerAvatar,
  sendMessageLoading,
  isLoading,
  typing,
  resetTyping,
}: any) => {
  const messageInput: any = useRef(null);

  const handleSendMessage = (event: any) => {
    event.preventDefault();
    if (messageInput.current.value.length > 0) {
      sendMessageLoading(owner, ownerAvatar, messageInput.current.value);
      messageInput.current.value = "";
    }
  };

  const handleTyping = () => {
    if (messageInput.current.value.length > 0) {
      typing(owner);
    } else {
      resetTyping(owner);
    }
  };

  return (
    <form onSubmit={handleSendMessage}>
      <input type="hidden" value={owner} />
      <input type="hidden" value={ownerAvatar} />
      <input
        type="text"
        ref={messageInput}
        className="chatApp__convInput"
        placeholder="Text message"
        onKeyDown={handleTyping}
        onKeyUp={handleTyping}
        // tabIndex="0"
      />
      {/* <div
        className={`px-4  ${
          isLoading ? "chatApp__convButton--loading" : ""
        }`}
        onClick={handleSendMessage}
      >
        <i className="material-icons">send</i>
      </div> */}
      <Button className="chatApp__convButton mt-2" disabled={isLoading}>
      <i className="ri ri-send"></i>Send
      </Button>
    </form>
  );
};

/* TypingIndicator component */
const TypingIndicator = ({ isTyping, owner }: any) => {
  let typersDisplay = "";
  let countTypers = 0;

  for (let key in isTyping) {
    if (key !== owner && isTyping[key]) {
      typersDisplay += `, ${key}`;
      countTypers++;
    }
  }

  typersDisplay = typersDisplay.substr(1);
  typersDisplay += countTypers > 1 ? " are " : " is ";

  return countTypers > 0 ? (
    <div className="chatApp__convTyping">
      {typersDisplay} writing
      <span className="chatApp__convTypingDot"></span>
    </div>
  ) : (
    <div className="chatApp__convTyping"></div>
  );
};

/* MessageItem component */
const MessageItem = ({ owner, sender, senderAvatar, message }: any) => {
  const messagePosition =
    owner === sender
      ? "chatApp__convMessageItem--right"
      : "chatApp__convMessageItem--left";
  return (
    <div className={`chatApp__convMessageItem ${messagePosition} clearfix`}>
      <img
        src={senderAvatar}
        alt={sender}
        className="chatApp__convMessageAvatar"
      />
      <div
        className="chatApp__convMessageValue"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </div>
  );
};

/* MessageList component */
const MessageList = ({ owner, messages }: any) => {
  return (
    <div className="chatApp__convTimeline">
      {messages
        .slice(0)
        .reverse()
        .map((messageItem: any) => (
          <MessageItem
            key={messageItem.id}
            owner={owner}
            sender={messageItem.sender}
            senderAvatar={messageItem.senderAvatar}
            message={messageItem.message}
          />
        ))}
    </div>
  );
};

/* ChatBox component */
const ChatBox = ({
  owner,
  ownerAvatar,
  sendMessage,
  typing,
  resetTyping,
  messages,
  isTyping,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const sendMessageLoading = (sender: any, senderAvatar: any, message: any) => {
    setIsLoading(true);
    sendMessage(sender, senderAvatar, message);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };

  return (
    <div className="chatApp__conv">
      <Title owner={owner} />
      <MessageList owner={owner} messages={messages} />
      <div className="chatApp__convSendMessage clearfix">
        <TypingIndicator owner={owner} isTyping={isTyping} />
        <InputMessage
          isLoading={isLoading}
          owner={owner}
          ownerAvatar={ownerAvatar}
          sendMessageLoading={sendMessageLoading}
          typing={typing}
          resetTyping={resetTyping}
        />
      </div>
    </div>
  );
};

/* ChatRoom component */
const ChatRoom = ({ setOpenModal }: any) => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      sender: "Shun",
      senderAvatar: "https://i.pravatar.cc/150?img=32",
      message: "Hello 👋",
    },
    {
      id: 2,
      sender: "Gabe",
      senderAvatar: "https://i.pravatar.cc/150?img=56",
      message: "Hey!",
    },
    {
      id: 3,
      sender: "Gabe",
      senderAvatar: "https://i.pravatar.cc/150?img=56",
      message: "How are you?",
    },
    {
      id: 4,
      sender: "Shun",
      senderAvatar: "https://i.pravatar.cc/150?img=32",
      message: "Great! It's been a while... 🙃",
    },
    {
      id: 5,
      sender: "Gabe",
      senderAvatar: "https://i.pravatar.cc/150?img=56",
      message: "Indeed.... We're gonna have to fix that. 🌮🍻",
    },
  ]);
  const [isTyping, setIsTyping] = useState<any>({});

  const sendMessage = (sender: any, senderAvatar: any, message: any) => {
    setTimeout(() => {
      let formattedMessage = detectURL(message);
      let newMessageItem = {
        id: messages.length + 1,
        sender,
        senderAvatar,
        message: formattedMessage,
      };
      setMessages((prevMessages) => [...prevMessages, newMessageItem]);
      resetTyping(sender);
    }, 400);
  };

  const typing = (writer: any) => {
    if (!isTyping[writer]) {
      setIsTyping((prevTyping: any) => ({ ...prevTyping, [writer]: true }));
    }
  };

  const resetTyping = (writer: any) => {
    setIsTyping((prevTyping: any) => ({ ...prevTyping, [writer]: false }));
  };

  const users = [
    { name: "Shun", avatar: "https://i.pravatar.cc/150?img=32" },
    // { name: "Gabe", avatar: "https://i.pravatar.cc/150?img=56" },
  ];

  return (
    <>
      {" "}
      <style>{`.clearfix::after {
	content: "";
	display: block;
	clear: both;
}

.chatApp__loaderWrapper {
	margin: 80px auto;
	text-align: center;
}
.chatApp__loaderText {
	color: #4870df;
	font-weight: bold;
}
.chatApp__loader,
.chatApp__loader::before,
.chatApp__loader::after {
	border-radius: 1em;
	width: 1em;
	height: 1em;
	animation-fill-mode: both;
	animation: loading 1.8s infinite ease-in-out;
}
.chatApp__loader {
	margin: auto;
	color: #4870df;
	font-size: 12px;
	position: relative;
	animation-delay: -0.16s;
}
.chatApp__loader::before,
.chatApp__loader::after {
	content: '';
	position: absolute;
	top: 0;
}
.chatApp__loader::before {
	left: -1.5em;
	animation-delay: -0.32s;
}
.chatApp__loader::after {
	left: 1.5em;
}
.chatApp__room {
	display: flex;
	align-content: flex-start;
	justify-content: center;
	padding: 5rem 20rem;
	animation: fadeChatApp 0.6s ease-in-out;
	animation-fill-mode: forwards;
}
.chatApp__conv {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	float: left;
	margin: 0 2.5rem;
	box-shadow: 0 35px 20px -30px rgba(0,0,0,0.1);
}
.chatApp__convTitle {
	display: inline-block;
	margin: 0 0 2rem 0;
	font-size: 18px;
	font-weight: bold;
	color: #f0f0f0;
	text-transform: uppercase;
	position: relative;
}
.chatApp__convTitle::after {
	content: '';
	position: absolute;
	background-color: #4870df;
	width: 100%;
	height: 2px;
	border-radius: 30px;
	left: 0;
	right: 0;
	bottom: -16px;
}
.chatApp__convTimeline {
	display: flex;
	height: 400px;
	padding: 1rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-color: #fff;
	flex-direction: column-reverse;
	overflow-y: auto;
}
.chatApp__convMessageItem {
	padding: 1rem 0 0 0;
	opacity: 0;
	transition: all 0.15s ease-in-out;
	animation: fadeNewMessage 0.5s;
	animation-fill-mode: forwards;
}
.chatApp__convMessageAvatar {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	image-rendering: -webkit-optimize-contrast;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.chatApp__convMessageValue {
	position: relative;
	max-width: 50%;
	min-height: 40px;
	padding: 0.75rem;
	border-radius: 4px;
	font-size: 14px;
	line-height: 18px;
	font-weight: bold;
	box-shadow: 0px 10px 10px -8px rgba(0,0,0,0.08);
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
}
.chatApp__convMessageItem--left .chatApp__convMessageAvatar {
	float: left;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue {
	float: left;
	text-align: left;
	margin: 0 0 0 1rem;
	color: #fff;
	background-color: #4870df;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue::before {
	content: '';
	position: absolute;
	top: 14px;
	left: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 10px 6px 0;
	border-color: transparent #4870df transparent transparent;
}
.chatApp__convMessageItem--right .chatApp__convMessageAvatar {
	float: right;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue {
	float: right;
	text-align: right;
	margin: 0 1rem 0 0;
	background-color: #fafafa;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue::after {
	content: '';
	position: absolute;
	top: 14px;
	right: -8px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 0 6px 10px;
	border-color: transparent transparent transparent #fafafa;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue a {
	color: #91ffb1;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--left .chatApp__convMessageValue a:focus {
	color: #75ff9e;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue a {
	color: #09f;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--right .chatApp__convMessageValue a:focus {
	color: #007dd1;
}
.chatApp__convSendMessage {
	position: relative;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: #fff;
}
.chatApp__convSendMessage {
	padding: 1rem;
}
.chatApp__convInput {
	float: left;
	height: 40px;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 40px;
	margin: 0 0.5rem 0 0;
	width: calc(100% - 52px);
	background-color: #dedfed;
	box-shadow: inset 0 0 0 2px #dedfed;
	font-size: 14px;
	font-family: 'Quicksand', sans-serif;
	font-weight: bold;
	transition: 0.15s all ease-in-out;
	box-sizing: border-box;
}
.chatApp__convInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #b1b1b1;
	opacity: 1;
}
.chatApp__convInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #b1b1b1;
}
.chatApp__convInput::-ms-input-placeholder { /* Microsoft Edge */
	color: #b1b1b1;
}
.chatApp__convInput:focus, .chatApp__convInput:active {
	box-shadow: inset 0 0 0 2px #7690d6;
}
.chatApp__convInput:focus {
	outline: none;
}
.chatApp__convButton {
	float: right;
	position: relative;
	width: 100px;
	height: 40px;
	cursor: pointer;
	padding: 8px;
	border-radius: 30px;
	color: #fff;
	background-color: #4870df;
	text-align: center;
	box-shadow: 0px 14px 10px -8px rgba(0,0,0,0.2);
	transition: 0.15s all ease-in-out;
	box-sizing: border-box;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.chatApp__convButton:hover {
	background-color: #3c559c;
}
.chatApp__convButton i {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	padding: 0 0 0 1px;
	font-size: 22px;
}
.chatApp__convButton--loading {
	cursor: wait;
	background-color: #4870df;
}
.chatApp__convButton--loading:hover {
	background-color: #4870df;
}
.chatApp__convButton--loading::before, .chatApp__convButton--loading::after {
	content: '';
	position: absolute;
	z-index: 1;
	display: block;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	margin: auto;
	border-radius: 40px;
	background-color: #fff;
}
.chatApp__convButton--loading::after {
	animation: loadSendMessage 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
}
.chatApp__convButton--loading i {
	display: none;
}
.chatApp__convSendMessage--typing .chatApp__convTyping {
	display: block;
}
.chatApp__convTyping {
	position: absolute;
	top: 0;
	font-size: 10px;
	font-weight: bold;
}
.chatApp__convTypingDot {
	position: relative;
	left: 4px;
	display: inline-block;
	width: 2px;
	height: 2px;
	background-color: #606060;
	animation: typingMessage 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.chatApp__convTypingDot::before, .chatApp__convTypingDot::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 2px;
	height: 2px;
	background-color: #606060;
	animation: typingMessage 1.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.chatApp__convTypingDot::before {
	left: 4px;
	animation-delay: 0.5s;
}
.chatApp__convTypingDot::after {
	left: 8px;
	animation-delay: 1s;
}
@media screen and (max-width:768px) {
	.chatApp__room { flex-wrap: wrap; padding: 2rem 1rem; }
	.chatApp__conv { flex-basis: 100%; min-width: auto; width: 100%; margin: 0; }
	.chatApp__conv:first-of-type { margin: 0 0 4rem 0; }
}
@keyframes loading {
	0%, 80%, 100% { box-shadow: 0 1em 0 -1.3em; }
	40% { box-shadow: 0 1em 0 0; }
}
@keyframes fadeChatApp {
	0% { opacity: 0; transform: translateY(1rem); }
	100% { opacity: 1; transform: translateY(0px); }
}
@keyframes fadeNewMessage {
	0% { opacity: 0; transform: translateY(1rem); }
	100% { opacity: 1; transform: translateY(0px); }
}
@keyframes loadSendMessage {
	0% { opacity: 1; width: 4px; height: 4px; }
	100% { opacity: 0; width: 100%; height: 100%; }
}
@keyframes typingMessage {
	0% { background-color: #606060; }
	50% { background-color: #fff; }
	60% { background-color: #606060; }
} 
  /* The popup chat - hidden by default */
  .chat-popup {
    display: block;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  } .form-container {
    max-width: 500px;
    padding: 10px;
    background-color: white;
  }
  `}</style>
      <div className="chat-popup">
        <div className="form-container01 find-giver-chat-form">
          <Button 
          className="chat-modal-close"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <i className="ri-close-line"></i>
          </Button>
          {users.map((user, index) => (
            <ChatBox
              key={index}
              owner={user.name}
              ownerAvatar={user.avatar}
              sendMessage={sendMessage}
              typing={typing}
              resetTyping={resetTyping}
              messages={messages}
              isTyping={isTyping}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default ChatRoom;
