export default function GoogleMap() {
  return (
    <>
      <section className="section-box mt-20">
        <div className="section-box wow animate__animated animate__fadeIn">
          <div className="container">
            <div className="text-start">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                We are Located at
              </h2>
              <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Find trusted care and support workers near you
              </p>
            </div>
            <div className="box-swiper mt-50 australia-map01">
              <iframe
                width="800"
                height="700"
                // frameborder="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13804065.984854717!2d139.4190549947332!3d-32.36196953102922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0dcb74f75e4b0d%3A0x1780af1122c49f2d!2sNew%20South%20Wales%2C%20Australia!5e0!3m2!1sen!2sin!4v1724070338610!5m2!1sen!2sin"
                // allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
