import React from "react";
import constants from "../../Utils/Constants/Constants";

const ProfileCard = ({
  src,
  alt,
  name,
  title,
  description,
  size = 100,
  borderRadius = "50%",
  border = "2px solid #ddd",
  shadow = "0 4px 8px rgba(0,0,0,0.1)",
  status = "online",
  image,
}: any) => {
  const pictureStyles: any = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: borderRadius,
    border: border,
    boxShadow: shadow,
    objectFit: "cover",
    position: "relative",
  };

  const statusStyles: any = {
    position: "absolute",
    bottom: "5px",
    right: "5px",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    // backgroundColor: status === "online" ? "green" : "red",
    border: "2px solid white",
  };

  const cardStyles: any = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  };

  const detailsStyles: any = {
    display: "flex",
    flexDirection: "column",
  };

  const nameStyles: any = {
    margin: "0",
    fontSize: "1.2rem",
    fontWeight: "bold",
  };

  const titleStyles: any = {
    margin: "0",
    fontSize: "1rem",
    color: "#666",
  };

  const descriptionStyles: any = {
    margin: "0",
    fontSize: "0.9rem",
    color: "#888",
  };

  return (
    <>
      {" "}
      <div style={cardStyles}>
        {image === false ? null : (
          <img
            src={src ? src : constants.DEFUALT_IMAGE_PROFILE?.IMAGE}
            alt={alt}
            style={pictureStyles}
          />
        )}     
        <div>
          <p style={nameStyles}>{name}</p>
          <div style={detailsStyles}>
            <p style={titleStyles}>{title}</p>
          </div>
          <p style={descriptionStyles}>{description}</p>
        </div>   
      </div>
      
    </>
  );
};

export default ProfileCard;
