export default function Reviews()
{

    return(<>  <div className="tab-pane fade show active">
        <h4>Comments</h4>

        <div className="author-bio mt-40 bg-white">
          <div className="author-des">
            <div className="author-image mb-15 justify-content-between">
              <div className="d-float-center">
                <div className="person-img">
                  <img
                    className="avatar"
                    src="https://i.pravatar.cc/100"
                    alt=""
                  />
                </div>
                <div className="author-infor">
                  <h6 className="mt-0 mb-0">Robert Fox</h6>
                  <div className="star-rating d-float-center my-0">
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                    <i className="fa fa-star text-warning"></i>
                  </div>
                </div>
              </div>
              <p className="mb-0 color-text-paragraph-2 font-sm">
                August 25, 2022
              </p>
            </div>
            <p className="font-md color-text-paragraph">
              Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed ultricies interdum massa
              nec fermentum. Phasellus interdum dignissim
              rhoncus. Nam vitae semper magna. Donec massa
              enim
            </p>
          </div>
        </div>
      </div></>)
}