import React from "react";
import { Helmet } from "react-helmet";
import RoutesHandler from "../Pages/Routes/index";
import { useQuery } from "react-query";
// Your React component
const MetaList = () => {
  //   const {
  //     data: meta,
  //     error: metaError,
  //     isLoading: metaLoading,
  //     refetch: metaListRefetch,
  //   }: any = useQuery([`meta`], () => getSIngleMeta("1"), {
  //     keepPreviousData: true,
  //   });
  // Dynamic data for meta tags
  //   const pageTitle: any = meta?.data?.[0]?.meta_title;
  //   const pageDescription: any = meta?.data?.[0]?.meta_body;

  return (
    <div>
      {/* Other component content */}
      <RoutesHandler />
      {/* Use Helmet to set dynamic meta tags */}
      {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet> */}
    </div>
  );
};

export default MetaList;
