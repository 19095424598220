import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import {
  AuthChecker,
  setValidationErrors,
} from "../../../../../Utils/helpers/helpers";
import Swal from "sweetalert2";

export const RegisterSubmitHandlerCareSeeker = (
  values: any,
  actions: any,
  navigate: any,
  setRegisterCode: Function,
  setOtpDisplay: Function,
  setErrorBackend: Function
) => {
  actions.setSubmitting(true);
  api
    .post("/user/careseeker_registration", values, false)
    .then(async function ([success, response]: any) {
      // console.log(response, "response?.data?.status");
      if (response?.status === true) {
        setRegisterCode(response?.registration_code);
        localStorage.setItem("REGISTER_TOKEN", response?.registration_code);

        setOtpDisplay(2);
        actions.setSubmitting(false);
      }
      if (response?.status === false) {
        setErrorBackend(response?.message);
      }
      if (response?.errors) {
        actions.setSubmitting(false);
        setValidationErrors(response?.errors, actions);
      }
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        setErrorBackend(err?.response?.data?.message);
        // toast.error(err?.response?.data?.message, {
        //   toastId: "Invalid-login-details",
        // });
      } else {
        Swal.fire({
          title: "Error",
          text: "Unexpected error occurred",
          icon: "error",
        });
        // toast?.error("Unexpected error occurred", {
        //   toastId: "error-occurred",
        // });
      }
    });
};
export const OTPSubmitHandlerCareSeeker = (
  values: any,
  actions: any,
  navigate: any,
  register_code: any,
  setOtpDisplay: Function,
  setCentered: any
) => {
  actions.setSubmitting(true);
  api
    .post(
      "/user/careseeker_registration/verify_otp",
      {
        otp: values.otp.join(""),
        registration_code:
          register_code || localStorage.getItem("REGISTER_TOKEN"),
      },
      false
    )
    .then(async function ([success, response]: any) {
      // console.log(response, "response?.data?.statusanu");
      if (response?.status === true) {
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
        });
        localStorage.setItem("AUTH_TOKEN", response?.token);
        localStorage?.removeItem("REGISTER_TOKEN");
        setCentered(true);
        // navigate("/success-careseeker");
        actions.setSubmitting(false);
      }
      if (response?.status === false) {
        // toast?.error(response?.message, {
        //   toastId: "error-occurred",
        // });
        Swal.fire({
          title: "Error!",
          text: response?.message,
          icon: "error",
        });
        actions.setSubmitting(false);
      }
      // if (response?.errors) {
      //   actions.setSubmitting(false);
      //   setValidationErrors(response?.errors, actions);
      // }
    })
    .catch((err) => {
      // console.log(err, "jjjj");
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        // toast?.error(err?.response?.data?.message, {
        //   toastId: "error-occurred",
        // });
        Swal.fire({
          title: "Error!",
          text: err?.response?.data?.message,
          icon: "error",
        });
      } else {
        // setValidationErrors(err, actions);
        // toast?.error("Unexpected error occurred", {
        //   toastId: "error-occurred",
        // });
        Swal.fire({
          title: "Error!",
          text: err?.response?.data?.message,
          icon: "error",
        });
      }
    });
};
