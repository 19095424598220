import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function HowItWOrk() {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <section className="section-box mb-80">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__ animate__fadeInUp animated">
              How It Works
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__ animate__fadeInUp animated">
              Just via some simple steps, you will find your ideal support
              worker you'r looking for!
            </p>
          </div>
          <div className="mt-70">
            <div className="row">
              <div className="col-lg-4">
                <div className="box-step step-1">
                  <h1 className="number-element">1</h1>
                  <h4 className="mb-20">
                    Register an
                    <br className="d-none d-lg-block" />
                    account to start
                  </h4>
                  <p className="font-lg color-text-paragraph-2">
                    Register Safely and Securely on
                    <br className="d-none d-lg-block" />
                    our platform.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box-step step-2">
                  <h1 className="number-element">2</h1>
                  <h4 className="mb-20">
                    Explore over
                    <br className="d-none d-lg-block" />
                    thousands of profiles
                  </h4>
                  <p className="font-lg color-text-paragraph-2">
                    Find exactly the services that match
                    <br className="d-none d-lg-block" />
                    your needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box-step">
                  <h1 className="number-element">3</h1>
                  <h4 className="mb-20">
                    Find the most
                    <br className="d-none d-lg-block" />
                    suitable support worker
                  </h4>
                  <p className="font-lg color-text-paragraph-2">
                    Find local workers who are most suited to your requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-50 text-center">
            {/* <a
              className="btn btn-default"
              href="/register"
              target="_blank"
            >
              Register
            </a> */}
            <Button
              className="btn btn-default"
              onClick={() => navigate("/register")}
            >
              {" "}
              Register
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
