import React from "react";
import {
  Banner,
  Coustomer,
  HowItWOrk,
  GoogleMap,
  OurSupport,
  Connect,
  Slogan,
  TopWorkers,
  Count,
  FindMyLocation,
  News,
  SomeWords,
  Subscribe,
} from "../../Routes/Imports";

const HomeIndex: React.FC = () => {
  return (
    <>
      <main className="main">
        <section className="section-box">
          <Banner />
          <Coustomer />
        </section>
        <HowItWOrk />
        <GoogleMap />
        <OurSupport />
        <Connect />
        <Slogan />
        <TopWorkers />
        <Count />
        <FindMyLocation />
        <News />
        <SomeWords />
        <Subscribe />
      </main>
    </>
  );
};

export default HomeIndex;
