import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { modalPropType } from "../../Types";
import constants from "../../Utils/Constants/Constants";


const ModalContainer = ({
  isOpen,
  setIsopen,
  title = "",
  children,
}: modalPropType) => {
  return (
    <>
      <style>
        {`
          .download-app-popup .head-section .app-link-head {        
              margin-bottom: 30px;
          }
        `}
      </style>    
      <Modal 
        className="download-app-popup"       
        isOpen={isOpen}
        scrollable={true}
        centered={false}
        fullscreen={true}
        dialogClassName="modal-lg modal-xl modal-fullscreen profile-created-modal01"
        toggle={() => setIsopen(false)}
      >
        <ModalHeader className="border-0" toggle={() => setIsopen(false)}>
          <span className="title-head">{title}</span>
          </ModalHeader>
        <ModalBody>
          {/* {children} */}
          <div className="row andriod-ios-app-download">
              {/* <div className="col-md-12">
                <div className="app-link-content text-center">
                  <div className="text-center mb-0 logo-div01">
                    <img
                      src={`${constants.IMAGE_PATH.IMAG_PATH}template/success.gif`}
                      alt=""
                      className="justify-content-center"
                      style={{
                        width: "150px",
                      }}
                    />
                  </div>
                  <h4 className="sub-head">Profile Created</h4>
                  <p className="sub-text1">Your profile has been verified successfully, 
                    now explore over thousands of care givers 
                    profiles and find most suitable support working in your community.</p>                    
                </div>
                <hr className="app-border-block"></hr>
              </div>               */}
              <div className="col-md-12">
                <div className="head-section d-float-center justify-content-center">
                  <h3 className="app-link-head">
                    <span className="text1">Download</span>&nbsp;
                    <span className="logo">
                    <img
                      src={`${constants.IMAGE_PATH.IMAG_PATH}template/fav.png`}
                      alt="joxBox"
                    />
                    </span>
                    <span className="site-name">USS</span>&nbsp;
                    <span className="text2">App to Get Started.</span>
                  </h3>
                </div>
              </div>
              <div className="col-md-6 col-6 app-scanner-box app-left">
                <div className="qr-box">
                  <a href="#">
                    <img 
                      className="qr-img"                    
                      srcSet={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                      alt="QR Code"
                    />
                    <p className="get-app-text">
                      <img
                        className="qr-img"
                        src={`${constants.IMAGE_PATH.IMAG_PATH}template/app-store-btn.png`}
                        alt="QR Code"
                      />
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-6 app-scanner-box app-right">
                <div className="qr-box">
                  <a href="#">
                    <img 
                      className="qr-img"                    
                      srcSet={`${constants.IMAGE_PATH.IMAG_PATH}template/apps.jpg`}
                      alt="QR Code"
                    />
                    <p className="get-app-text">
                      <img
                        className="qr-img"
                        src={`${constants.IMAGE_PATH.IMAG_PATH}template/android-btn.png`}
                        alt="QR Code"
                      />
                    </p>
                  </a>
                </div>
              </div>              
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalContainer;
