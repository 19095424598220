import { Container } from "reactstrap";
import TimeSlot from "./Booking/TimeSlots";
import Chats from "./components/ChatBox";

export default function ChatTwo() {
  return (
    <>
      {/* <Chats /> */}
      <Container>
        <TimeSlot />
      </Container>
    </>
  );
}
