import constants from "../../Utils/Constants/Constants";

export default function Loader() {
  return (
    <>
      {" "}
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="text-center">
              <img
                src={`${constants.IMAGE_PATH.IMAG_PATH}template/loader.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
