import { AboutusMainPage, AboutasBanner, News, Subscribe } from "../../Routes/Imports";

export default function AboutasIndex() {
  return (
    <>
      {" "}
      <main className="main">
        <AboutasBanner />
        <AboutusMainPage />
        {/* <News/> */}
        <Subscribe/>
      </main>
    </>
  );
}
