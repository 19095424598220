/* eslint-disable jsx-a11y/anchor-is-valid */
import constants from "../../../../Utils/Constants/Constants";
import { Link } from "react-router-dom";
import {
  CapitalFirstLetter,
  capitalizeFirstLetterOfEachWord,
  removeHtmlTags,
  truncateText,
} from "../../../../Utils/helpers/helpers";
import Loader from "../../../../components/Loader/Loader";

export default function ServiceMainPage({ dataList, dataLoading }: any) {
  return (
    <>
      <section className="section-box mt-30">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="content-page">
                <div className="row">
                  {dataLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {dataList?.data?.length === 0 ? (
                        <span className="text-center">No data found</span>
                      ) : (
                        <>
                          {dataList?.data?.map((item: any, index: any) => {
                            return (
                              <div
                                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-list01"
                                key={index}
                              >
                                <div className="card-grid-2 hover-up">
                                  <div className="card-grid-2-image-left pb-1">
                                    <div className="image-box">
                                      {item?.thumbnailImg ? (
                                        <img
                                          className="service-img01"
                                          src={`${item?.thumbnailImg}`}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          className="service-img01"
                                          src={`${constants.DEFUALT_IMAGE_SERVICE.IMAGE}`}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="right-info">
                                      <a className="name-job" href="#">
                                        {capitalizeFirstLetterOfEachWord(item?.name)}
                                      </a>
                                      <p className="font-sm color-text-paragraph mt-0 mb-3 service-description01">
                                        {/* <div
                                          dangerouslySetInnerHTML={{
                                            __html: ,
                                          }}
                                        ></div> */}
                                        {capitalizeFirstLetterOfEachWord(
                                          truncateText(
                                            removeHtmlTags(item?.details),
                                            50
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card-block-info">
                                    <div className="card-2-bottom mt-0">
                                      <div className="row">
                                        <div className="col-lg-7 col-7">
                                          <div className="d-float-center h-100">
                                            <span className="card-text-price">
                                              {item?.serviceProvidersCount}
                                            </span>
                                            <span className="text-muted">
                                              {" "}
                                              Care Givers
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-5 col-5 text-end">
                                          <div className="btn btn-apply-now">
                                            <Link
                                              className="text-white"
                                              to={`/services-detailes?id=${item?.id}`}
                                              // target="_blank"
                                            >
                                              Read More
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {/* <PaginationComponent
                            totalPages={dataList?.meta?.lastPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                          /> */}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
