import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import InputSelectCommon from "../../../components/CommonSelect/InputSelect";
import {
  PAYMENT_MODE,
  SUPPORT_FOR_YOU,
} from "../../../Utils/Constants/select_options";
import {
  addModalInitialValuesHandlerStep2,
  ProfileSubmitHandlerStep2,
  ScrollToFieldError,
} from "../ProfileMethods/ProfileSubmitHandlers";
import { useNavigate } from "react-router-dom";
import { getPaymentData } from "../../../Api/WebsiteApi";
import { useQuery } from "react-query";
import { initialValuesStep2 } from "../ProfileMethods/profileInitialValues";
import { validationSchema2 } from "../ProfileMethods/ProfileValidation";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Step3 = ({ setStep, Userdata, ListRefetch, UserLoading_data }: any) => {
  const navigate = useNavigate();

  const {
    data: paymentData,
    error: UserError,
    isLoading: UserLoading,
    refetch: UserListRefetch,
  }: any = useQuery([`paymentdata`], () => getPaymentData(), {
    keepPreviousData: true,
  });
  const [addModalInitialValues, setAddModalInitialValues] =
    useState(initialValuesStep2);
  // console.log(Userdata, "Userdata");
  useEffect(() => {
    if (Userdata?.id) {
      addModalInitialValuesHandlerStep2(Userdata, setAddModalInitialValues);
    } else {
      setAddModalInitialValues(initialValuesStep2);
    }
  }, [Userdata, Userdata?.id]);
  return (
    <>
      {UserLoading_data ? (
        <Skeleton count={5} />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={addModalInitialValues}
          validationSchema={validationSchema2}
          onSubmit={(values, actions) => {
            ProfileSubmitHandlerStep2(
              values,
              actions,
              setStep,
              navigate,
              ListRefetch,
              Userdata?.careseeker_details?.supportFor?.id
            );
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            isSubmitting,
          }) => (
            <>
              {" "}
              {/* <ScrollToFieldError/> */}
              {/* {console.log(values, "asxhasgxha", errors)} */}
              <Form
                className="seeker-registration-form3"
                onSubmit={handleSubmit}
              >
                <div className="text-center mb-3 logo-div01">
                  <h3 className="block-subTitle text-center uss-text-info">
                    {/* <span className="uss-text-primary">Create</span> Account */}
                  </h3>
                </div>
                <FormGroup>
                  <Label>Payment mode </Label>
                  <InputSelectCommon
                    size={1}
                    data={paymentData}
                    name="payment_mode"
                    selectClass={`form-control col-12 ${`${
                      errors.payment_mode ? "is-invalid" : "is-valid"
                    }`}`}
                  />
                  <ErrorMessage
                    name="payment_mode"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {/* 
                {values?.payment_mode === "2" ||
                values?.payment_mode === "7" ? (
                  <></>
                ) : (
                  <> */}

                <FormGroup>
                  <Label>Plan manager name</Label>
                  <Field
                    type="text"
                    name="plan_manager_name"
                    className={`form-control ${`${
                      errors.plan_manager_name ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.plan_manager_name}
                    disabled={
                      values?.payment_mode === "2" ||
                      values?.payment_mode === "7"
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage
                    name="plan_manager_name"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>NDIS</Label>
                  <Field
                    type="text"
                    name="ndis"
                    className={`form-control ${`${
                      errors.ndis ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.ndis}
                    disabled={
                      values?.payment_mode === "2" ||
                      values?.payment_mode === "7"
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage
                    name="ndis"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Contact name</Label>
                  <Field
                    type="text"
                    name="contact_name_ndis"
                    className={`form-control ${`${
                      errors.contact_name_ndis ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.contact_name_ndis}
                    disabled={
                      values?.payment_mode === "2" ||
                      values?.payment_mode === "7"
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage
                    name="contact_name_ndis"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Contact number</Label>
                  <Field
                    type="text"
                    name="contact_number_dis"
                    className={`form-control ${`${
                      errors.contact_number_dis ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.contact_number_dis}
                    disabled={
                      values?.payment_mode === "2" ||
                      values?.payment_mode === "7"
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage
                    name="contact_number_dis"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Contact email</Label>
                  <Field
                    type="text"
                    name="ndis_contact_email"
                    className={`form-control ${`${
                      errors.ndis_contact_email ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.ndis_contact_email}
                    disabled={
                      values?.payment_mode === "2" ||
                      values?.payment_mode === "7"
                        ? true
                        : false
                    }
                  />
                  <ErrorMessage
                    name="ndis_contact_email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {/* </>
                )} */}

                <FormGroup>
                  <Label>Email address for sending invoice</Label>
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${`${
                      errors.email ? "is-invalid" : "is-valid"
                    }`}`}
                    values={values?.email}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
                {Object.keys(errors)?.length === 1 ? (
                  <>
                    {" "}
                    <div>
                      <Alert color="danger" className="mt-30" id="g">
                        Please make sure all fields are filled correctly.
                      </Alert>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="text-center mt-5 d-float-center justify-content-center gap-15">
                  <Button
                    className="btn btn-brand-2"
                    type="submit"
                    onClick={() => {
                      // navigate("/create-profile-careseeker/1");
                      setStep(1);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>&nbsp;BACK
                  </Button>
                  <Button
                    className="btn btn-brand-1"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <Spinner size="sm"></Spinner>}
                    Save & Next&nbsp;<i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};
export default Step3;
