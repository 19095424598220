import constants from "../../../../Utils/Constants/Constants";

export default function FindMyLocation() {
  return (
    <>
      <section className="section-box mt-50 find-by-location">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
              Find by Location
            </h2>
            <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              Connect with and book support workers in your community
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row mt-50">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-image-top hover-up">
                <a href="#">
                  <div className="image">
                    <img
                      alt=""
                      src={`${constants.IMAGE_PATH.IMAG_PATH}location/location1.jpg`}
                    />
                  </div>
                </a>
                <div className="informations">
                  <a href="#">
                    <h5>Albert Park, VIC 3206</h5>
                  </a>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <span className="text-14 color-text-paragraph-2">
                        0 Care seekers
                      </span>
                    </div>
                    <div className="col-lg-6 col-6 text-end">
                      <span className="color-text-paragraph-2 text-14">
                        0 Care givers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-image-top hover-up">
                <a href="#">
                  <div className="image">
                    <img
                      alt=""
                      src={`${constants.IMAGE_PATH.IMAG_PATH}location/location2.jpg`}
                    />
                  </div>
                </a>
                <div className="informations">
                  <a href="#">
                    <h5>Liverpool, NSW 2170</h5>
                  </a>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <span className="text-14 color-text-paragraph-2">
                        0 Care seekers
                      </span>
                    </div>
                    <div className="col-lg-6 col-6 text-end">
                      <span className="color-text-paragraph-2 text-14">
                        0 Care givers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-image-top hover-up">
                <a href="#">
                  <div className="image">
                    <img
                      alt=""
                      src={`${constants.IMAGE_PATH.IMAG_PATH}location/location3.jpg`}
                    />
                  </div>
                </a>
                <div className="informations">
                  <a href="#">
                    <h5>Stafford, QLD 4053</h5>
                  </a>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <span className="text-14 color-text-paragraph-2">
                        0 Care seekers
                      </span>
                    </div>
                    <div className="col-lg-6 col-6 text-end">
                      <span className="color-text-paragraph-2 text-14">
                        0 Care givers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-image-top hover-up">
                <a href="#">
                  <div className="image">
                    <img
                      alt=""
                      src={`${constants.IMAGE_PATH.IMAG_PATH}location/location4.jpg`}
                    />
                  </div>
                </a>
                <div className="informations">
                  <a href="#">
                    <h5>North Haven, SA 5018</h5>
                  </a>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <span className="text-14 color-text-paragraph-2">
                        0 Care seekers
                      </span>
                    </div>
                    <div className="col-lg-6 col-6 text-end">
                      <span className="color-text-paragraph-2 text-14">
                        0 Care givers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-image-top hover-up">
                <a href="#">
                  <div className="image">
                    <img
                      alt=""
                      src={`${constants.IMAGE_PATH.IMAG_PATH}location/location5.jpg`}
                    />
                  </div>
                </a>
                <div className="informations">
                  <a href="#">
                    <h5>Perth, WA 6000</h5>
                  </a>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <span className="text-14 color-text-paragraph-2">
                        0 Care seekers
                      </span>
                    </div>
                    <div className="col-lg-6 col-6 text-end">
                      <span className="color-text-paragraph-2 text-14">
                        0 Care givers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="card-image-top hover-up">
                <a href="#">
                  <div className="image">
                    <img
                      alt=""
                      src={`${constants.IMAGE_PATH.IMAG_PATH}location/location6.jpg`}
                    />
                  </div>
                </a>
                <div className="informations">
                  <a href="#">
                    <h5>Launceston, TAS 7250</h5>
                  </a>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <span className="text-14 color-text-paragraph-2">
                        0 Care seekers
                      </span>
                    </div>
                    <div className="col-lg-6 col-6 text-end">
                      <span className="color-text-paragraph-2 text-14">
                        0 Care givers
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
