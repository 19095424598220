import DatePicker from "react-datepicker";
import { useQuery } from "react-query";
import { getHolidays } from "../../Api/WebsiteApi";

export default function DatePickerReact({
  selected,
  onChange,
  className,
  maxDate,
  minDate,
  name,
  ref,
  placeholderText
}: any) {
  const {
    data: holiday,
    error: holidayError,
    isLoading: holidayLoading,
    refetch: holidayListRefetch,
  }: any = useQuery([`public_holidays`], getHolidays, {
    keepPreviousData: true,
  });
  return (
    <>
      <DatePicker
        name={name}
        selected={selected}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        placeholderText={placeholderText ? placeholderText : "DD/MM/YYYY"}
        className={className}
        showIcon
        maxDate={maxDate}
        minDate={minDate}
        holidays={holiday}
        ref={ref}
      />
    </>
  );
}
