export default function ContactBanner() {
  return (
    <>
      {" "}
      <section className="section-box">
        <div className="breacrumb-cover bg-img-about">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="mb-10">Contact Us</h2>
              </div>
              {/* <div className="col-lg-6 text-lg-end">
                <ul className="breadcrumbs mt-40">
                  <li>
                    <a className="home-icon" href="#">
                      Home
                    </a>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
