export default function GoogleMap() {
  return (
    <>

      <style>
        {`
          .box-info-contact {
              padding: 0px;
          }
          .box-map {
              margin-bottom: 0px;
          }
          .box-map iframe {
            height: 80vh;
          }
        `}
      </style>

      <section className="section-box mt-80">
        <div className="col-md-12 m-0 p-0">
          <div className="box-info-contact">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="box-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13804065.984854717!2d139.4190549947332!3d-32.36196953102922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0dcb74f75e4b0d%3A0x1780af1122c49f2d!2sNew%20South%20Wales%2C%20Australia!5e0!3m2!1sen!2sin!4v1724070338610!5m2!1sen!2sin"
                // allowfullscreen=""
                loading="lazy"
                // referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
}
