import { Button } from "reactstrap";
import { registerType } from "../../../../Types";
import "../../../../Styles/register-style.css";
import { useNavigate } from "react-router-dom";
import constants from "../../../../Utils/Constants/Constants";

export default function CareSeeker(dataTypes: registerType) {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <div className="col-md-6">
        <div className="card card-blog text-center card-guest">
          <div className="card-image">
            <img
              className="img care-seeker01"
              src={`${constants.IMAGE_PATH.IMAG_PATH}register/care-seeker001.png`}
              alt=""
            />
          </div>
          <div className="table pt-0">
            <h4 className="card-caption mt-4">
              <a>Sign up as Care Seeker</a>
            </h4>
            <hr />
            <p className="mb-2 info-p01">
              Locate nearby dependable carers and support personnel in your own
              community.
            </p>
            <ul className="boxPoints">
              <li>Easy way to get trusted support workers.</li>
              <li>Search & connect the right support worker faster.</li>
              <li>Improve the quality of service for your care support.</li>
            </ul>
            <p className="mt-4">
              <Button
                className="btn uss-bg-primary text-white btn1"
                onClick={() => navigate("/careseeker-create-account")}
              >
                Create account
              </Button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
