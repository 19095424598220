import { Button } from "reactstrap";

export default function TimeSlot() {
  return (
    <>
      <div className="pt-5 border-t border-gray-200 dark:border-gray-800 flex sm:flex-row flex-col sm:space-x-5 rtl:space-x-reverse">
        <div className="sm:ms-7 sm:ps-5 sm:border-s border-gray-200 dark:border-gray-800 w-full sm:max-w-[15rem] mt-5 sm:mt-0">
          <div
            id="timetable"
            className="grid w-full grid-cols-2 gap-2 mt-5 row"
          >
            <div className="col-3">
              <Button className="  " outline>
                10:00 AM
              </Button>
            </div>
            <div className="col-3">
              <Button className="  " outline>
                10:00 AM
              </Button>
            </div>
            <div className="col-3">
              <Button className="  " outline>
                10:00 AM
              </Button>
            </div>
            <div className="col-3">
              <Button className="  " outline>
                10:00 AM
              </Button>
            </div>
            <div className="col-3">
              <Button className="  " outline>
                10:00 AM
              </Button>
            </div>z
           
          </div>
        </div>
      </div>
    </>
  );
}
