import { Link } from "react-router-dom";

export default function Connect() {
  return (
    <>
      <style>
        {`
          .box-we-hiring::before {
            left: 0px;
          }
        `}
      </style>{" "}
      <div className="section-box mb-30 mt-50 home-sec08">
        <div className="container">
          <div className="box-we-hiring">
            <div className="text-1">
              <span className="text-we-are">Ready to</span>
              <span className="text-hiring">CONNECT?</span>
            </div>
            <div className="text-2">
              Click the <span className="color-brand-1">button </span> and leave
              your contact number.{" "}
              <span className="color-brand-1">We’ll reach </span> out to you.
            </div>
            <div className="text-3">
              <div className="btn btn-apply btn-apply-icon">
                <Link className="text-white" to="/contact">
                  Connect
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
