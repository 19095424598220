import constants from "../../../../Utils/Constants/Constants";

export default function Slogan() {
  return (
    <>
      <section className="section-box overflow-visible mt-100 mb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="box-image-job">
                <img
                  className="img-job-1"
                  alt=""
                  src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/img-chart.png`}
                />
                <img
                  className="img-job-2"
                  alt=""
                  src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/controlcard.png`}
                />
                <figure className="wow animate__animated animate__fadeIn">
                  <img
                    alt=""
                    src={`${constants.IMAGE_PATH.IMAG_PATH}page/homepage1/img1.png`}
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="content-job-inner">
                <span className="color-text-mutted text-32">
                  Trustworthy
                  <br />
                  support community
                </span>
                <h2 className="text-52 wow animate__animated animate__fadeInUp">
                  Find The One That&rsquo;s{" "}
                  <span className="color-brand-2">Right</span> For You
                </h2>
                <div className="mt-40 pr-50 text-md-lh28 wow animate__animated animate__fadeInUp">
                  With years of experience in the Australian market, we offer
                  choices for more than 29 different types of Supports and
                  Services. You may filter your preferences and choose the one
                  that’s RIGHT for you.
                </div>
                <div className="mt-40">
                  <div className="wow animate__animated animate__fadeInUp">
                    <a className="btn btn-default" href="/register">
                      Sign up
                    </a>
                    {/* <a className="btn btn-link" href="#">
                      Learn More
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
